import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import config from "../config";
import PrimaryButton from "./PrimaryButton";
import AIcon from "react-native-vector-icons/AntDesign";
import * as DocumentPicker from "expo-document-picker";

export default class AddInvoiceOverlay extends Component {
  state = {
    Attachment: [],
    attachmentLengthLimit: 1,
    error: false,
    error_file: [],
  };
  // handleSubmit(values) {
  //   console.log(this.props);
  //   this.props.OnClose(this.state.Attachment);
  // }

  componentDidMount() {
    this.setState({
      Attachment: this.props.OldAttachments,
    });
  }
  render() {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    function niceBytes(x) {
      let l = 0,
        n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    }
    return (
      <View style={{ flex: 1 }}>
        <SafeAreaView
          style={{
            flex: 1,
            backgroundColor: "#707070",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              backgroundColor: "#FFF",
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                padding: 20,
              }}
            >
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => this.props.close()}
              >
                <AIcon name='left' style={styles.backButton} />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 30,
                  fontFamily: "Roboto-Medium",
                  color: config.primary_color,
                }}
              >
                {/* {this.props.mode == 'add' ? 'Add Milestone' : 'Edit Milestone'} */}
                Add Attachments
              </Text>
            </View>
            {this.state.Attachment.length <= 1 ? (
              <TouchableOpacity
                onPress={() => {
                  DocumentPicker.getDocumentAsync({
                    type: ["application/pdf", "image/png", "image/jpg"],
                  })
                    .then(result => {
                      if (result.size < 5000000)
                        this.setState({
                          Attachment: [...this.state.Attachment, result],
                        });
                      else
                        return alert(
                          "Invalid file type or File size exceeds 5Mb"
                        );
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                style={{
                  backgroundColor: "#FFF",
                  marginHorizontal: "10%",
                  paddingVertical: "3%",
                  paddingHorizontal: "5%",
                  marginTop: 10,
                  elevation: 3,
                  //fff
                  borderRadius: 20,
                  alignItems: "center",
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                }}
              >
                <AIcon
                  name='pluscircleo'
                  style={{ color: config.primary_color, fontSize: 80 }}
                />
              </TouchableOpacity>
            ) : null}

            {this.state.Attachment.length !== 0 ? (
              <View
                style={{
                  paddingHorizontal: "5%",
                  paddingVertical: "2%",
                  marginTop: 10,
                  marginHorizontal: "10%",
                  borderRadius: 20,
                  borderColor: "#333",
                  borderWidth: 1,
                  alignItems: "center",
                }}
              >
                {this.state.Attachment.map(item => {
                  return (
                    <Text
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {item.name} - {niceBytes(item.size)}
                    </Text>
                  );
                })}
              </View>
            ) : null}
            <Text
              style={{
                textAlign: "left",
                fontSize: 12,
                color: "#481380",
                marginTop: 9,
                marginBottom: 10,
                marginLeft: 45,
                marginRight: 72,
                lineHeight: 20,
              }}
            >
              Max file size allowed is 5mb, files can only be pdf or jpg/png.
              Max 2 Files allowed
            </Text>
            <View
              style={{
                marginBottom: 20,
                marginLeft: 40,
                marginRight: 40,
              }}
            >
              {this.state.error ? (
                <PrimaryButton
                  content='Back'
                  clicked={this.setState({
                    error: false,
                  })}
                />
              ) : (
                <PrimaryButton
                  content='Save'
                  clicked={() => {
                    this.props.OnClose(this.state.Attachment);
                  }}
                />
              )}
            </View>
          </View>
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    fontSize: 25,
    height: 30,
    marginRight: 20,
  },
});
