//import liraries
import React, {useEffect, useState, useRef} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  TextInput,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  Keyboard,
} from 'react-native';
import config from '../config';
import EIcon from 'react-native-vector-icons/Entypo';
import FIcon from 'react-native-vector-icons/FontAwesome';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import DIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {connect} from 'react-redux';
import {
  initTopicMessageState,
  topicMessageReceived,
  sendTopicMessage,
  setTopicPageState,
  getTopicChatMessages,
  unsubscribeTopicSocketChannel,
} from '../state-management/actions/topicmessages.action';
import MessageBubble from './MessageBubble';
import imageService from '../services/image.service';
// import ImageResizer from 'react-native-image-resizer';
// import RNFetchBlob from 'rn-fetch-blob';
// import ImagePicker from 'react-native-image-picker';

const TopicChat = props => {
  const [text, setText] = useState('');
  const [height, setHeight] = useState(0);
  const chatRef = useRef(null);
  const [page, setPage] = useState(2);
  const [img, setImg] = useState(null);

  // const openAttachment = async () => {
  //   let options = {
  //     storageOptions: {
  //       skipBackup: true,
  //       path: 'images',
  //     },
  //   };
  //   ImagePicker.launchImageLibrary(options, response => {
  //     if (response.didCancel) {
  //       setImg(null);
  //     } else if (response.error) {
  //       setImg(null);
  //     } else if (response.customButton) {
  //       setImg(null);
  //     } else {
  //       processImage(response).then((result) => {
          
  //       }).catch((err) => {
          
  //       });
  //     }
  //   });
  // };

  // const processImage = async(response)=>{
  //   try{
  //     var res= await ImageResizer.createResizedImage(`data:${response.type};base64,${response.data}`, 300, 300, 'PNG', 80)
  //     var result= await RNFetchBlob.fs.readFile(res.uri,"base64")
  //     setImg(`data:image/png;base64,${result}`);
  //   }catch (error) {
  //     console.log(error);
  //   } 
  // }

  // const openCamera = () => {
  //   let options = {
  //     storageOptions: {
  //       skipBackup: true,
  //       path: 'images',
  //     },
  //   };
  //   ImagePicker.launchCamera(options, response => {
  //     if (response.didCancel) {
  //       setImg(null);
  //     } else if (response.error) {
  //       setImg(null);
  //     } else if (response.customButton) {
  //       setImg(null);
  //     } else {
  //       processImage(response).then((result) => {
          
  //       }).catch((err) => {
          
  //       });
  //     }
  //   });
  // };


  useEffect(() => {
    props.initTopicMessageState(props.parent_data);
    // const keyboardListener = Keyboard.addListener('keyboardDidShow', e => {
    //   setTimeout(() => {
    //     if(chatRef.current)
    //       chatRef.current.scrollToEnd({animated: false});
    //   }, 500);
    // });
    // const keyboardHideListener = Keyboard.addListener('keyboardDidHide', () => {
    //   setTimeout(() => {
    //     if(chatRef.current)
    //       chatRef.current.scrollToEnd({animated: false});
    //   }, 500);
    // });
    return function cleanup() {
      // keyboardListener.remove()
      // keyboardHideListener.remove()
      props.unsubscribeTopicSocketChannel();
      props.setTopicPageState(false);
    };
  }, []);

  return (
    <View style={{flex: 1}}>
      {props.error ? (
        <View
          style={{
            flex: 1,
            backgroundColor: '#f7f7f9',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <DIcon
            name="message-bulleted-off"
            style={{fontSize: 50, color: config.primary_color}}
          />
          <Text
            style={{
              fontSize: 12,
              color: config.primary_color,
              fontFamily: 'Roboto-Regular',
              marginTop: 10,
            }}>
            Your Partner is not Vouched yet
          </Text>
        </View>
      ) : (
        <View style={{flex: 1, backgroundColor: '#f7f7f9'}}>
          {props.messages.length == 0 ? (
            <View style={{flex: 1, alignItems: 'center'}}>
              <Text
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  color: config.primary_color,
                  fontFamily: 'Roboto-Bold',
                  marginTop: '45%',
                  marginHorizontal: '10%',
                }}>
                You can communicate with the Buyer/Seller about the order here.
                You can ask questions, exchange any relevant information here.
              </Text>
            </View>
          ) : (
            <FlatList
              ref={chatRef}
              data={props.messages}
              keyExtractor={(item, index) => index.toString()}
              keyboardDismissMode="on-drag"
              keyboardShouldPersistTaps="handled"
              renderItem={({item, index}) => {
                return (
                  <MessageBubble
                    me={item.sentBy == props.current_user_id}
                    {...item}
                    index={index}
                    previousMessage={
                      props.messages.length > 1 && index >= 1
                        ? props.messages[index - 1]
                        : null
                    }
                  />
                );
              }}
              onContentSizeChange={() => {
                chatRef.current.scrollToEnd({animated: true});
              }}
              // onScroll={event => {
              //   var isTopReached = event.nativeEvent.contentOffset.y < 10;
              //   if (isTopReached) {
              //     setPage(page + 1);
              //     props.getTopicChatMessages(page);
              //     console.log('Called');
              //   }
              // }}
            />
          )}
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 10,
              marginTop: 10,
              backgroundColor: 'transparent',
            }}>
            <View style={[styles.inputContainer,{borderRadius:img?10:100}]}>
            <ScrollView style={{flex:1,maxHeight:400}}>
            {
                img?
                <ImageBackground
            source={{uri: img}}
            resizeMode="cover"
            style={{
              width: 200,
              height: 150,
              marginTop:5,
              alignItems:'flex-end'
            }}
            imageStyle={{
              borderRadius: 5,
              borderColor: 'black',
              borderWidth: 1,
              // marginHorizontal: 3,
            }}
          >
          <TouchableOpacity 
          onPress={()=>{
            setImg(null)
          }}
          style={{marginTop:5,marginRight:2}}>
            <EIcon name='circle-with-cross' style={{fontSize:20,color:'#fff'}}/>
          </TouchableOpacity>
          </ImageBackground>
                :null
              }
                <TextInput
                multiline={true}
                placeholder={'Type your message here..'}
                placeholderTextColor={config.primary_color}
                onChangeText={text => {
                  setText(text);
                }}
                onContentSizeChange={event =>
                  setHeight(event.nativeEvent.contentSize.height)
                }
                style={[styles.textInput, {height: Math.min(100, height)}]}
                value={text}
              />
          </ScrollView>

              <TouchableOpacity 
              onPress={() => {
                var el = document.getElementById("profile-pic");
                el.click();
                el.addEventListener("change",($event)=>{
                  var file = $event.target.files?.length?$event.target.files[0]:null;
                  if(file){
                    if(file.size<2000000){
                    imageService.toBase64(file).then(data=>{
                    setImg(data)
                  })
                }
                else{
                  window.alert('Max image size is 2 MB')
                }
                  }
                })
              }}              
              style={{alignSelf: 'center'}}>
                <EIcon
                  name='attachment'
                  style={{
                    fontSize: 20,
                    color: '#473BB3',
                    marginRight: 20,
                  }}
                />
          <input type="file" id="profile-pic" hidden  />
              </TouchableOpacity>
            </View>
            {/* {text ? ( */}
            <TouchableOpacity
              style={styles.sendIconContainer}
              onPress={() => {
            if(text || img){
                props.sendTopicMessage({message: text, image: img});
                setText('');
                setImg(null);
            }
              }}>
                {props.isMessageSending ? (
            <ActivityIndicator size={'small'} color={config.primary_color} />
          ) : (
              <MIcon
                name="send"
                style={{fontSize: 22, color: config.primary_color}}
              />
          )}
            </TouchableOpacity>
            {/* ) : null} */}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
  textInput: {
    flex: 1,
    // paddingHorizontal: 5,
    paddingVertical: 20,
    fontSize: 16,
    fontFamily: 'OpenSans-Regular',
    color: config.primary_color,
    marginHorizontal: 15,
  },
  itemOut: {
    alignSelf: 'flex-end',
  },
  item: {
    marginTop: 10,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#D2B2FF',
    borderRadius: 300,
    paddingHorizontal: 5,
    marginRight: 10,
    paddingVertical: 2,
  },
  balloon: {
    maxWidth: 250,
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 20,
  },
  sendIconContainer: {
    alignSelf: 'center',
    backgroundColor: '#FFF',
    elevation: 5,
    marginHorizontal: 10,
    width: 55,
    height: 55,
    borderRadius: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: '#FFF',
    paddingHorizontal: '2%',
    elevation: 4,
    borderRadius: 100,
    marginHorizontal: 10,
    alignItems:'center'
  },
});

export default connect(
  state => ({...state.topicmessages}),
  {
    initTopicMessageState,
    topicMessageReceived,
    sendTopicMessage,
    setTopicPageState,
    getTopicChatMessages,
    unsubscribeTopicSocketChannel,
  },
)(TopicChat);
