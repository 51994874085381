import React, { useEffect } from "react";
import { Image, View } from "react-native";
import config from "../config";

const LeftPanel = (props) => {
  return (
    <View
      style={{
        flex: 0.3,
        elevation: 4,
        backgroundColor:
          props.routeName == "GuestPaymentCheckout"
            ? "#262262"
            : config.primary_color,
        zIndex: 5,
      }}>
      <Image
        source={require(props.routeName == "GuestPaymentCheckout"? "../assets/images/captabl_white_logo.png":"../assets/images/white_logo.png")}
        style={{ height: 100, width: 100, margin: 15 }}
      />
    </View>
  );
};

export default LeftPanel;
