//import liraries
import React, {useEffect, useState, useRef} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  TextInput,
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  Dimensions,
} from 'react-native';
import config from '../../config';
import EIcon from 'react-native-vector-icons/Entypo';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import {connect} from 'react-redux';
import {
  setMessagePageState,
  initMessageState,
  sendMessage,
  getChatMessages,
  unsubscribeSocketChannel,
} from '../../state-management/actions/messages.action';
import MessageBubble from '../MessageBubble';
import imageService from '../../services/image.service';

const MessagesTab = props => {
  const [text, setText] = useState('');
  const [height, setHeight] = useState(0);
  const chatRef = useRef(null);
  const [img, setImg] = useState(null);

  useEffect(() => {
    return () => {
      props.unsubscribeSocketChannel();
      props.setMessagePageState(false);
    };
  }, []);

  return (
    <View style={{flex: 1, backgroundColor: props.isLoading?'#FFF':'#f7f7f9'}}>
      {props.isLoading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator size="large" color={config.primary_color} />
    </View>
          ) : (
        <FlatList
          ref={chatRef}
          data={props.messages}
          keyExtractor={(item, index) => index.toString()}
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
          renderItem={({item, index}) => {
            return (
              <MessageBubble
                me={item.sentBy == props.current_user_id}
                {...item}
                index={index}
                previousMessage={
                  props.messages.length > 1 && index >= 1
                    ? props.messages[index - 1]
                    : null
                }
              />
            );
          }}
          onContentSizeChange={() => {
            chatRef.current.scrollToEnd({animated: false});
          }}
        />
      )}
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 10,
          marginTop: 10,
          backgroundColor: 'transparent',
        }}>
        <View style={[styles.inputContainer, {borderRadius: img ? 10 : 100}]}>
          <ScrollView
            style={{flex: 1, maxHeight: 400, backgroundColor: 'transparent'}}>
            {img ? (
              <ImageBackground
                source={{uri: img}}
                resizeMode="cover"
                style={{
                  width: 200,
                  height: 150,
                  marginTop: 5,
                  alignItems: 'flex-end',
                }}
                imageStyle={{
                  borderRadius: 5,
                  borderColor: 'black',
                  borderWidth: 1,
                  marginHorizontal: 3,
                }}>
                <TouchableOpacity
                  onPress={() => {
                    setImg(null);
                  }}
                  style={{marginTop: 5, marginRight: 2}}>
                  <EIcon
                    name="circle-with-cross"
                    style={{fontSize: 20, color: '#fff'}}
                  />
                </TouchableOpacity>
              </ImageBackground>
            ) : null}
            <TextInput
              multiline={true}
              placeholder={'Type your message here..'}
              placeholderTextColor={config.primary_color}
              onChangeText={text => {
                setText(text);
              }}
              onContentSizeChange={event => {
                setHeight(event.nativeEvent.contentSize.height);
              }}
              style={[styles.textInput, {height: Math.min(100, height)}]}
              value={text}
            />
          </ScrollView>

          <TouchableOpacity
            style={{alignSelf: 'center'}}
            onPress={() => {
              var el = document.getElementById("profile-pic");
              el.click();
              el.addEventListener("change",($event)=>{
                var file = $event.target.files?.length?$event.target.files[0]:null;
                if(file){
                  if(file.size<2000000){
                  imageService.toBase64(file).then(data=>{
                  setImg(data)
                })
              }
              else{
                window.alert('Max image size is 2 MB')
              }
                }
              })
            }}
            >
            <EIcon
              name="attachment"
              style={{
                fontSize: 20,
                color: config.primary_color,
                marginRight: 20,
              }}
            />
          <input type="file" id="profile-pic" hidden  />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={styles.sendIconContainer}
          onPress={() => {
            if (text || img) {
              props.sendMessage({message: text, image: img});
              setText('');
              setImg(null);
            }
          }}>
          {props.isMessageSending ? (
            <ActivityIndicator size={'small'} color={config.primary_color} />
          ) : (
            <MIcon
              name="send"
              style={{fontSize: 22, color: config.primary_color}}
            />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 0,
    paddingVertical: 15,
    fontSize: 16,
    fontFamily: 'OpenSans-Regular',
    color: config.primary_color,
    marginHorizontal: 15,
  },
  itemOut: {
    alignSelf: 'flex-end',
  },
  item: {
    marginTop: 10,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#D2B2FF',
    borderRadius: 300,
    paddingHorizontal: 5,
    marginRight: 10,
    paddingVertical: 2,
  },
  balloon: {
    maxWidth: 250,
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 20,
  },
  sendIconContainer: {
    alignSelf: 'center',
    backgroundColor: '#FFF',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginHorizontal: 10,
    width: 55,
    height: 55,
    borderRadius: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: '#FFF',
    paddingHorizontal: '2%',
    elevation: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginHorizontal: 10,
    position: 'relative',
    alignItems:'center'
  },
});

export default connect(
  state => ({...state.messages}),
  {
    initMessageState,
    sendMessage,
    setMessagePageState,
    getChatMessages,
    unsubscribeSocketChannel,
  },
)(MessagesTab);
