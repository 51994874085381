import React, {Component} from 'react'
import {
  SafeAreaView,
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Platform,
  Linking,
} from 'react-native'
import Header from '../../components/Header'
import FIcon from 'react-native-vector-icons/FontAwesome'
import config from '../../config'
export default class WhatsApp extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  whatsApp = () => {
    try {
      // Linking.openURL('https://wa.me/+917676401442')
      window.open('https://wa.me/+917676401442', '_blank')
    } catch (error) {
      alert('Make sure you have Whatsapp to avail this feature')
    }
  }
  call = () => {
    try {
      let phoneNumber = ''

      if (Platform.OS === 'android') {
        phoneNumber = 'tel:${+917676401442}'
      } else {
        phoneNumber = 'telprompt:${+917676401442}'
      }

      Linking.openURL(phoneNumber)
    } catch (error) {}
  }

  render () {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <Header title={'Help'} navigation={this.props.navigation} />
        <View style={{flex: 1, marginHorizontal: '10%', marginVertical: '20%'}}>
          <TouchableOpacity
            onPress={() => this.whatsApp()}
            style={{flexDirection: 'column', alignItems: 'center'}}>
            {/* <FIcon name='whatsapp' style={{fontSize:50,color:config.primary_color}} /> */}
            <Image
              source={require('../../assets/images/whatsapp2.png')}
              style={{width: 100, height: 100}}
              resizeMode='contain'
            />
            <Text
              style={{
                fontFamily: 'Roboto-Medium',
                fontSize: 14,
                color: '#828282',
                marginLeft: 20,
                paddingTop: 20,
              }}>
              Chat with us through WhatsApp (9am -9pm)
            </Text>
          </TouchableOpacity>
          {/* <Text style={styles.orText}>or</Text>
          <TouchableOpacity
            onPress={() => this.call()}
            style={{flexDirection: 'column', alignItems: 'center'}}>
            <FIcon
              name='phone'
              style={{fontSize: 70, color: config.primary_color}}
            />
            <Text
              style={{
                fontFamily: 'Roboto-Medium',
                fontSize: 14,
                color: '#828282',
                marginLeft: 20,
                paddingTop: 15,
              }}>
              Call us (9am -9pm)
            </Text>
          </TouchableOpacity> */}
        </View>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  orText: {
    textAlign: 'center',
    color: '#464646',
    fontSize: 20,
    marginVertical: '15%',
  },
})
