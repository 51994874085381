//import liraries
import React, {Component, useState, useEffect, useRef} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,ActivityIndicator,
} from 'react-native';
import {connect} from 'react-redux';
import config from '../../config';
// import ViewPager from '@react-native-community/viewpager'
import MessagesTab from '../../components/payment_user/MessagesTab';
import {
  changePage,
  resetState,
} from '../../state-management/actions/paymentuser.actions';
import PersonHeader from '../../components/PersonHeader';
import AIcon from 'react-native-vector-icons/AntDesign';
import { TabView, TabBar} from 'react-native-tab-view';
import {loadPaticularUserTransactions} from '../../state-management/actions/paumentstab.action';
import {
  setMessagePageState,
  initMessageState,
  unsubscribeSocketChannel,
  sendMessage,
} from '../../state-management/actions/messages.action';
import AwesomeAlert from 'react-native-awesome-alerts';
import PaymentsTab from '../../components/payment_user/PaymentsTab';

const PaymentUser = props => {
  const pagerRef = useRef(null)
  const [loader,setLoader] = useState(true);
  const [temp,setTemp] = useState(false);

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'Payments', title: 'Payments' },
    { key: 'Messages', title: 'Messages' },
  ]);

  useEffect(() => {
    const routeData = props.route.params
    if (routeData.hasOwnProperty('subRoute')) {
      if (routeData.subRoute == 'Messages') {
        setIndex(1)
      }
    }
    if (routeData.hasOwnProperty('showAlert')) {
      if (routeData.showAlert) {
        setTemp(true)
      }
    }
    return () => {
      props.resetState()
    }
  }, [])

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Payments':
        return <PaymentsTab {...props}/>
      case 'Messages':
        return <MessagesTab 
        parent_data={{
          to_user: props.route.params.uid,
        }}
/>
      default:
        return null;
    }
  };
  const renderTabBar = props => (
    <TabBar

      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ color, margin: 8 ,fontFamily:'Roboto-Bold'}}>
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#FFF',
      shadowOffset: { height: 0, width: 0 }, 
      shadowColor: 'transparent',
      shadowOpacity: 0,
      elevation: 0  }}
      activeColor = {config.primary_color}
      inactiveColor = {'#000'}
    />
  )

  const _renderLazyPlaceholder = ({ route }) => <LazyPlaceholder route={route} />;

  const LazyPlaceholder = ({ route }) => (
    <View style={styles.scene}>
      <Text>Loading {route.title}…</Text>
    </View>
  );  

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
    props.loadPaticularUserTransactions(props.route.params.uid)
    });
    props.initMessageState({
      to_user: props.route.params.uid,
    });
    return () => {
      props.unsubscribeSocketChannel();
      props.setMessagePageState(false);
      unsubscribe
    }
  }, [])

  if (props.error) {
    alert(props.error)
  }

  return props.paymentsTab.isLoading?
  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
  <ActivityIndicator size="large" color={config.primary_color} />
</View>:(
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
              <View style={{flexDirection: 'row', padding: 20, paddingBottom: 0}}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <PersonHeader 
          onClick={()=>{props.navigation.navigate('UserProfile',{value:props.paymentsTab.clickedUser?.username})}}
          {...props.paymentsTab.clickedUser}
          />
        </View>
      <View style={{flex:1}}>
         {/* <PersonHeader {...props.paymentsTab.clickedUser} /> */}
      


<TabView
    lazy={true}
    renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      // initialLayout={initialLayout}
      renderLazyPlaceholder={_renderLazyPlaceholder}
      style={{
        shadowOffset: { height: 0, width: 0 }, 
 shadowColor: 'transparent',
 shadowOpacity: 0,
 elevation: 0 
      }}
    />


      </View>
      <AwesomeAlert
        show={temp}
        title=""
        message=""
        messageStyle={{ textAlign: 'center' }}
        customView={(
          <View
            style={{
              alignItems: 'center',
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 10,
            }}>
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 16,
                color: '#707070',
                lineHeight: 21,
              }}>
                   {props.paymentsTab.clickedUser?.company_name?props.paymentsTab.clickedUser?.company_name:props.paymentsTab.clickedUser.firstname}  has been notified that you are interested in his profile take the conversation forward by letting him know why
            </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          setTemp(false);
        }}
      />
    </SafeAreaView>
  )


}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    fontSize: 25,
    marginTop:5,
    marginRight: 10,
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  default: {
    textTransform: 'capitalize',
  },
  viewPager: {
    flex: 1,
  },
  scene: {
    flex: 1,
  },
})

//make this component available to the app
export default connect(
  state => ({paymentsTab: {...state.paymentstab}, ...state.paymentuser}),
  {changePage, resetState,loadPaticularUserTransactions,  setMessagePageState,
    initMessageState,
    unsubscribeSocketChannel,sendMessage},
)(PaymentUser)
