//import liraries
import React, {useRef, useState, useEffect} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  FlatList,
  TextInput,
  Animated,
  Dimensions,
  Easing
} from 'react-native'
import {Button, Icon, Item} from 'native-base'
import config from '../../config'
import FIcon from 'react-native-vector-icons/Fontisto'
import {connect} from 'react-redux'
import {
  loadData,
  changeMode,
  updateWindowCount,
  endReached,
  resetMode
} from '../../state-management/actions/contacts.action'
import ContactItem from '../../components/ContactItem'
// import BuySellLoader from '../../components/SkeletonLoaders/BuySellLoader'
import Header from '../../components/Header'
import AIcon from 'react-native-vector-icons/AntDesign';
import {
  searchContacts,
  initContacts,
} from '../../state-management/actions/searchcontacts.action';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { resetContactInfo } from '../../state-management/actions/category.action'
import PaymentDetailsButton from '../../components/PaymentDetailsButton'

const {height, width} = Dimensions.get('window');
const containerWidth = 350
const initiallTabWidth = 0.4 * containerWidth
const containerHalfWidth = 0.5 * containerWidth


const auth = firebase.auth;
const firestore = firebase.firestore;
const BuySellPage = props => {

  
  const width = useRef(new Animated.Value(initiallTabWidth)).current
  const height = useRef(new Animated.Value(0)).current
  const left = useRef(new Animated.Value(0)).current
  const right = useRef(new Animated.Value(0)).current
  const [showerror,setShowerror] = useState(false);

  
  useEffect(() => {
    props.loadData()
    if(props.mode){
      start()
      change()
    }
    return () => {
      props.resetMode()
    }
  }, [])
  const [numVal,setNumVal] = useState('')

  if (props.error) {
    alert(props.error)
  }

  const searchNumber=(value) =>{
    setNumVal(value)
    if(value.length==10 && (auth().currentUser?.phoneNumber?.slice(3)!=value)){

      props.searchContacts(value)
    }
  }

  const next = () => {
    if(!props.mode){
      setShowerror(true)
    }else{
    if(props.route.params?.flow_code==1){
      props.navigation.navigate('RecommendationDetails', {
        mobile: props.category.mobile,
        name: props.category.name,
        flow_code:props.route.params?.flow_code
      })
    }else{
    props.navigation.navigate('PaymentDetails', {
      mobile: props.category.mobile,
      name: props.category.name,
      seller_count:props.category.seller_count,
      seller_review:props.category.seller_review,
      buyer_review:props.category.buyer_review,
      buyer_count:props.category.buyer_count
    })
  }
  }
  }

  const start = () => {
    Animated.parallel([
          Animated.timing(width, {
            toValue: containerHalfWidth,
            duration: 800,
            easing:Easing.in
          }),
    ]
    ).start()
  };

const change = () => {
  Animated.parallel([
          Animated.timing(left, {
            toValue: 15,
            duration: 800,
            easing:Easing.in
          }),
          Animated.timing(right, {
            toValue: 20,
            duration: 800,
            easing:Easing.in
          })
        ]).start()
  }

  const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

  
  if(props.category.name){return(
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => {
                    props.resetContactInfo()
                    props.navigation.goBack()}}>
                  <AIcon name='left' style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: 'Roboto-Medium',
                    color: config.primary_color,
                  }}>
                  {props.route.params?.flow_code==0?`I am`:`I Have`}
                </Text>
              </View>
              <View style={{alignItems: 'center'}}>
                <View style={styles.toggleContainer}>
                  <AnimatedTouchable
                    style={{
                          borderRadius: 50,
                          width:width,
                          elevation: 5,
                          paddingVertical:props.mode == 'buying'?
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [5,12]
                          })
                          :5,
                          left:left,
                          zIndex: props.mode == 'buying'?100:0,
                          backgroundColor: props.mode == 'buying'?config.primary_color:
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: ['#FFF','#828282']
                          }),
                          borderWidth:width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [2,0]
                          }),
                          borderColor:config.primary_color,
                          }}
                    onPress={() => {
                      start()
                      props.changeMode('buying')
                      change()
                      setShowerror(false)
                      }}>
                    <Text style={[styles.toggleText,
                    {
                      color:props.mode == 'buying' || props.mode == 'selling' ? '#FFF' : config.primary_color,
                      textAlign: 'center',
                      fontFamily: 'OpenSans-Regular',
                      fontSize: props.mode == 'buying' ? 18 : 14 ,
                    }
                    ]}>
                    {props.route.params?.flow_code==1?`Bought from`:`Buying from`}
                      </Text>
                  </AnimatedTouchable>
                  <AnimatedTouchable
                    style={{
                      borderRadius: 50,
                      width:width,
                      elevation: 5,
                      paddingVertical:props.mode == 'selling'?width.interpolate({
                        inputRange: [initiallTabWidth,containerHalfWidth],
                        outputRange: [5,12]
                      }):5,
                      right:right,
                      zIndex: props.mode == 'selling'?100:0,
                          backgroundColor: props.mode == 'selling'?config.primary_color:
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: ['#FFF','#828282']
                          }),
                          borderWidth:width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [2,0]
                          }),
                          borderColor:config.primary_color,
                      }}
                    onPress={() => {
                      start()
                      props.changeMode('selling')
                      change()
                      setShowerror(false)
                      }}>
                    <Text style={[styles.toggleText,
                    {
                      color:props.mode == 'buying' || props.mode == 'selling' ? '#FFF' : config.primary_color,
                      textAlign: 'center',
                      fontFamily: 'OpenSans-Regular',
                      fontSize: props.mode == 'selling' ? 18 : 14 ,
                      // paddingVertical: 6,
                    }
                    ]}>
                      {props.route.params?.flow_code==1?`Sold to`:`Selling to`}
                      </Text>
                  </AnimatedTouchable>
                </View>
                {showerror?<Text style={{textAlign:'center',color:'#D92E0B',fontFamily:'OpenSans-Regular',fontSize:12,marginTop:20}}>Please select what you would{'\n'}like to start a payment for.</Text>:null}
              </View>
              <TouchableOpacity
              onPress={()=>{
                next()
              }}
              style={{
                flexDirection: 'row',
                borderBottomColor: '#AAA',
                borderBottomWidth: 0.5,
                marginTop: 50,
                paddingBottom: 8,
                marginHorizontal: '8%'
              }}>
                <View
                style={{
                  backgroundColor: 'orange',
                  width: 55,
                  height: 55,
                  justifyContent: 'center',
                  borderRadius: 50,
                  alignSelf: 'center',
                  borderColor:config.primary_color,
                  borderWidth:1
                }}>
                <Text
                  style={{
                    fontSize: 20,
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily:'Roboto-Bold'
                  }}>
                  {props.category.name[0]}
                </Text>
                </View>
                <View style={{flex: 1, justifyContent: 'space-evenly'}}>
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Bold',
              color: config.primary_color,
              paddingHorizontal: 10,
            }}>
            {props.category.name}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Regular',
              color: '#707070',
              paddingHorizontal: 10,
            }}>
            {props.category.mobile}
          </Text>
        </View>
        <View
            style={{
              backgroundColor: config.primary_color,
              width: 30,
              height: 30,
              justifyContent: 'center',
              borderRadius: 50,
              alignSelf: 'center',
            }}>
            <Text
              style={{
                fontSize: 15,
                color: '#FFF',
                textAlign: 'center',
                fontFamily:'OpenSans-Bold'
              }}>
              V
            </Text>
          </View>
          </TouchableOpacity>   
              </View>
              <View style={{flex:1}}></View>
              <PaymentDetailsButton content={'Proceed'}
                clicked={()=>{
                  next()
                }} />
    </SafeAreaView>
  )
  }

  function renderContact ({item, index}) {
    return (
      <ContactItem
        {...props}
        color={`${config.colors[index % config.colors.length]}`}
        thumbnail={false}
        vouched={false}
        name={`${item.name}`}
        mobile={`${item.mobile}`}
        clicked={() =>{
          if(!props.mode){
            setShowerror(true)
          }else{
          props.navigation.navigate('InviteUser', {name:item.name,mobile: item.mobile,flow_code:props.route.params?.flow_code})
          }
        }
        }
      />
    )
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      {props.isLoading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator size="large" color={config.primary_color} />
         </View>
      ) : (
        <FlatList
          ListHeaderComponent={
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => props.navigation.goBack()}>
                  <AIcon name='left' style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: 'Roboto-Medium',
                    color: config.primary_color,
                  }}>
                  {props.route.params?.flow_code==0?`I am`:`I Have`}
                  </Text>
              </View>
              <View style={{alignItems: 'center'}}>
                <View style={styles.toggleContainer}>
                  <AnimatedTouchable
                    style={{
                          borderRadius: 50,
                          width:width,
                          elevation: 5,
                          paddingVertical:props.mode == 'buying'?
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [5,12]
                          })
                          :5,
                          left:left,
                          zIndex: props.mode == 'buying'?100:0,
                          backgroundColor: props.mode == 'buying'?config.primary_color:
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: ['#FFF','#828282']
                          }),
                          borderWidth:width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [2,0]
                          }),
                          borderColor:config.primary_color,
                          }}
                    onPress={() => {
                      start()
                      props.changeMode('buying')
                      change()
                      setShowerror(false)
                      }}>
                    <Text style={[styles.toggleText,
                    {
                      color:props.mode == 'buying' || props.mode == 'selling' ? '#FFF' : config.primary_color,
                      textAlign: 'center',
                      fontFamily: 'OpenSans-Regular',
                      fontSize: props.mode == 'buying' ? 18 : 14 ,
                    }
                    ]}>
                    {props.route.params?.flow_code==1?`Bought from`:`Buying from`}
                      </Text>
                  </AnimatedTouchable>
                  <AnimatedTouchable
                    style={{
                      borderRadius: 50,
                      width:width,
                      elevation: 5,
                      paddingVertical:props.mode == 'selling'?width.interpolate({
                        inputRange: [initiallTabWidth,containerHalfWidth],
                        outputRange: [5,12]
                      }):5,
                      right:right,
                      zIndex: props.mode == 'selling'?100:0,
                          backgroundColor: props.mode == 'selling'?config.primary_color:
                          width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: ['#FFF','#828282']
                          }),
                          borderWidth:width.interpolate({
                            inputRange: [initiallTabWidth,containerHalfWidth],
                            outputRange: [2,0]
                          }),
                          borderColor:config.primary_color,
                      }}
                    onPress={() => {
                      start()
                      props.changeMode('selling')
                      change()
                      setShowerror(false)
                      }}>
                    <Text style={[styles.toggleText,
                    {
                      color:props.mode == 'buying' || props.mode == 'selling' ? '#FFF' : config.primary_color,
                      textAlign: 'center',
                      fontFamily: 'OpenSans-Regular',
                      fontSize: props.mode == 'selling' ? 18 : 14 ,
                      // paddingVertical: 6,
                    }
                    ]}>
                      {props.route.params?.flow_code==1?`Sold to`:`Selling to`}
                      </Text>
                  </AnimatedTouchable>
                </View>
                {showerror?<Text style={{textAlign:'center',color:'#D92E0B',fontFamily:'OpenSans-Regular',fontSize:12,marginTop:20}}>Please select what you would{'\n'}like to start a payment for.</Text>:null}
              </View>

              <View style={{marginHorizontal: '8%', marginVertical: '5%'}}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'Roboto-Regular',
                    color: '#707070',
                    paddingVertical: 15,
                    paddingHorizontal: 10,
                  }}>
                  Search for People {'\n'}(V = Already on Vouch)
                </Text>

                {/* ************************  Search Bar ************************* */}

                <Item searchBar style={styles.searchItem}>
                  <Button transparent style={{marginRight: 10, padding: 0}}>
                    <FIcon name="search" style={styles.icon} />
                  </Button>
                  <TextInput
                    placeholder="Enter Mobile Number/ Username"
                    placeholderTextColor={config.textDullColor}
                    // autoFocus={true}
                    onChangeText={val => searchNumber(val)}
                    value={numVal}
                    style={{
                      fontSize: 14,
                      color: '#AAAAAA',
                      flex: 1,
                      paddingVertical:12
                    }}
                  />
                </Item>

                {/* ************************  Search Bar End    ************************* */}

              </View>
              
              <View>
                {
              props.searchcontacts.isLoading?
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  <ActivityIndicator size={'large'} color={config.primary_color} />
                </View>:
                !props.searchcontacts.searchResult.length?
                  numVal?
                  <ContactItem
                    color={`${config.colors[0]}`}
                    thumbnail={false}
                    vouched={false}
                    name={'New Number'}
                    mobile={numVal}
                    clicked={() =>{
                      if(!props.mode){
                        setShowerror(true)
                      }else{
                        props.navigation.navigate('InviteUser', {
                          mobile: numVal,
                          flow_code:props.route.params?.flow_code
                        })
                      }
                    }
                    }
                  />:null
                : null}
                {
                  props.searchcontacts.searchResult.map((item, index) => {
                    return (
                      <ContactItem
                        color={`${config.colors[index % config.colors.length]}`}
                        thumbnail={false}
                        vouched={true}
                        name={`${item.firstname} ${item.lastname}`}
                        mobile={`${item.mobile}`}
                        clicked={() =>{
                          if(!props.mode){
                            setShowerror(true)
                          }else{
                          if(props.route.params?.flow_code==1){
                            props.navigation.navigate('RecommendationDetails', {
                              mobile: item.mobile,
                              name: `${item.firstname} ${item.lastname}`,
                              flow_code:props.route.params?.flow_code
                            })
                          }else{
                          props.navigation.navigate('PaymentDetails', {
                            mobile: item.mobile,
                            name: `${item.firstname} ${item.lastname}`,
                            username: item.username
                          })
                        }
                      }
                        }}
                      />
                    );
                  })
                  }
                
              </View>
            </View>
          }
          data={props.tempContacts}
          renderItem={renderContact}
          keyExtractor={(item, index) => index.toString()}
          removeClippedSubviews={true}
          initialNumToRender={20}
          maxToRenderPerBatch={10}
          onMomentumScrollEnd={() => props.updateWindowCount()}
        />
      )}
    </SafeAreaView>
  )
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 30,
    marginRight: 30,
    fontSize:30,
    // marginTop:5
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 18, marginLeft: 5, color: config.primary_color},
  toggleText: {
    textAlign: 'center',
    fontFamily: 'OpenSans-Regular',
    paddingVertical: 6,
  },
  default: {
    paddingVertical: '1%',
  },
  searchItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingHorizontal: 15,
    paddingVertical: 0,
    borderRightWidth: 1,
    borderRightColor: '#DCDCDC',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderTopColor: '#DCDCDC',
    borderLeftColor: '#DCDCDC',
    backgroundColor: '#FFF',
    borderRadius: 30,
    elevation: 0,
    alignSelf: 'center',
  },
  searchItemText: {
    color: config.textDullColor,
    fontSize: 14,
    color: '#AAAAAA',
    flex: 1,
    textDecorationLine: 'none',
  },
  toggleContainer: {
    marginHorizontal: '8%',
    alignItems: 'center',
    width: containerWidth,
    justifyContent: 'space-around',
    borderRadius: 50,
    flexDirection: 'row',
    backgroundColor: '#FFF',
  }
})

//make this component available to the app
export default connect(state => ({category:{...state.category},searchcontacts: {...state.searchcontacts},...state.contacts}), {
  loadData,
  changeMode,
  updateWindowCount,
  endReached,
  searchContacts, initContacts,
  resetContactInfo,
  resetMode
})(BuySellPage)
