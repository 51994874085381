import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  StatusBar,
  ActivityIndicator,
} from "react-native";
import config from "../../config";
import AIcon from "react-native-vector-icons/AntDesign";
import FIcon from "react-native-vector-icons/FontAwesome";
import LivingIn from "../../components/Profile/LivingIn";
import KnownFor from "../../components/Profile/KnownFor";
import CommunicationPreferences from "../../components/Profile/CommunicationPreferences";
import RandomInsights from "../../components/Profile/RandomInsights";
import AddPhoto from "../../components/Profile/AddPhoto";
import {
  editProfile,
  changeActiveTab,
  addGigForSale,
  loadInitiallProfileData,
  addNewPortfolio,
  setPortfolio,
  setForSale,
} from "../../state-management/actions/editprofile.action";
import { connect } from "react-redux";
import OneLineAboutMe from "../../components/Profile/OneLineAboutMe";
import LanguagesKnown from "../../components/Profile/LanguagesKnown";
import { Modal } from "react-native-paper";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import imageService from "../../services/image.service";
import AddForSale from "../../components/Profile/AddForSale";
import AddPortfolio from "../../components/Profile/AddPortfolio";
import ProfileCard from "../../components/Profile/ProfileCard";
import MIcon from "react-native-vector-icons/MaterialCommunityIcons";
import EIcon from "react-native-vector-icons/Entypo";
import LinkSocials from "../../components/Profile/LinkSocials";

const EditProfile = props => {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "Basicinfo", title: "Basic info" },
    { key: "Forsale", title: "For sale" },
    { key: "Portfolio", title: "Portfolio" },
    { key: "Aboutme", title: "About me" },
  ]);
  const [livingInBool, setlivingInBool] = useState(false);
  const [oneLineBool, setoneLineBool] = useState(false);
  const [knownForBool, setknownForBool] = useState(false);
  const [knownLanguagesBool, setknownLanguagesBool] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [insights, setInsights] = useState(false);
  const [img, setImg] = useState(false);
  const [imageTypeName, setImageTypeName] = useState("");
  const [forsale, setForSale] = useState("");
  const [addPortfolio, setAddPortfolio] = useState(false);
  const [sociallinksmodel, setSocialLinksModel] = useState(false);

  useEffect(() => {
    props.loadInitiallProfileData();
  }, []);

  const imageUpload = imageName => {
    var el = document.getElementById("profile-pic");
    el.click();
    el.addEventListener("change", $event => {
      var file = $event.target.files?.length ? $event.target.files[0] : null;
      if (file) {
        if (file.size < 2000000) {
          imageService.toBase64(file).then(data => {
            file.data = data;
            props.editProfile(file, imageName, null);
          });
        } else {
          window.alert("Max image size is 2 MB");
        }
      }
    });
  };

  const FirstRoute = () => (
    <View style={styles.scene}>
      <View style={{ marginHorizontal: "7%" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              color: "#000",
              fontSize: 18,
            }}
          >
            Profile Picture
          </Text>
          <TouchableOpacity
            onPress={() => {
              imageUpload("profile_image_path");
            }}
            style={{ alignSelf: "flex-end", paddingTop: 5 }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        <input type='file' id='profile-pic' hidden />
        {props.profile.userProfile?.profile_image_path ? (
          <TouchableOpacity
            onPress={() => {
              imageUpload("profile_image_path");
            }}
            style={styles.imageContainer}
          >
            <Image
              source={{ uri: props.profile.userProfile?.profile_image_path }}
              resizeMode='cover'
              style={{
                width: 130,
                height: 130,
                borderRadius: 100,
              }}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => {
              imageUpload("profile_image_path");
            }}
            style={styles.imageContainer}
          >
            <FIcon
              name='camera'
              style={{ color: config.primary_color, fontSize: 60, padding: 35 }}
            />
          </TouchableOpacity>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              color: "#000",
              fontSize: 18,
            }}
          >
            Cover Picture
          </Text>
          <TouchableOpacity
            onPress={() => {
              imageUpload("cover_image_path");
            }}
            style={{ alignSelf: "flex-end", paddingTop: 5 }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        {props.profile.userProfile?.cover_image_path ? (
          <TouchableOpacity
            onPress={() => {
              imageUpload("cover_image_path");
            }}
            style={styles.imageContainer1}
          >
            <Image
              source={{ uri: props.profile.userProfile?.cover_image_path }}
              resizeMode='cover'
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => {
              imageUpload("cover_image_path");
            }}
            style={styles.imageContainer1}
          >
            <FIcon
              name='camera'
              style={{ color: config.primary_color, fontSize: 60 }}
            />
          </TouchableOpacity>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 40,
            marginBottom: 15,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              fontSize: 16,
              color: "#000",
              paddingLeft: 5,
            }}
          >
            Living in
          </Text>
          <TouchableOpacity
            onPress={() => {
              setlivingInBool(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        {props.profile.userProfile?.livingIn ? (
          <View
            style={{ flexDirection: "row", marginBottom: 25, marginTop: 10 }}
          >
            <FIcon
              name='map-marker'
              style={{ fontSize: 20, color: "#464646", marginLeft: 20 }}
            />
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
                marginLeft: 10,
              }}
            >
              {props.profile.userProfile?.livingIn}
            </Text>
          </View>
        ) : (
          <TouchableOpacity
            onPress={() => setlivingInBool(true)}
            style={{
              padding: 12,
              elevation: 2,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              backgroundColor: "#FFF",
              borderRadius: 10,
              marginBottom: 35,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: "#AAAAAA",
                fontFamily: "Roboto-Regular",
              }}
            >
              Select City
            </Text>
          </TouchableOpacity>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              fontSize: 16,
              color: "#000",
              paddingLeft: 5,
            }}
          >
            One liner about you
          </Text>
          <TouchableOpacity
            onPress={() => {
              setoneLineBool(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        {props.profile.userProfile?.one_line_about_me ? (
          <View
            style={{ flexDirection: "row", marginBottom: 25, marginTop: 10 }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
                marginLeft: 10,
              }}
            >
              {props.profile.userProfile?.one_line_about_me}
            </Text>
          </View>
        ) : (
          <TouchableOpacity
            onPress={() => setoneLineBool(true)}
            style={{
              padding: 12,
              elevation: 2,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              backgroundColor: "#FFF",
              borderRadius: 10,
              marginBottom: 35,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: "#AAAAAA",
                fontFamily: "Roboto-Regular",
              }}
            >
              Enter a one line description about you
            </Text>
          </TouchableOpacity>
        )}
        {props.profile.userProfile?.known_for ? (
          renderSkills()
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "OpenSans-SemiBold",
                  fontSize: 16,
                  color: "#000",
                  paddingLeft: 5,
                  paddingLeft: 5,
                }}
              >
                Known for
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setknownForBool(true);
                }}
              >
                <MIcon
                  name='pencil'
                  style={{ color: "#318EF8", fontSize: 20 }}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                setknownForBool(true);
              }}
              style={{
                padding: 12,
                elevation: 2,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                backgroundColor: "#FFF",
                borderRadius: 10,
                marginBottom: 35,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#AAAAAA",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Add skills or job you are known for
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {/* <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setSocialLinksModel(true);
            }}
            style={{
              flexDirection: "row",
              paddingHorizontal: 20,
              paddingVertical: 8,
              borderRadius: 100,
              backgroundColor: config.primary_color,
            }}
          >
            <Text
              style={{
                fontFamily: "OpenSans-SemiBold",
                color: "#FFF",
                alignSelf: "center",
                textAlign: "center",
                marginRight: 15,
              }}
            >
              Add social links
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
    </View>
  );

  const SecondRoute = () => (
    <View style={styles.scene}>
      <View style={{ marginHorizontal: "7%", paddingTop: "7%" }}>
        <TouchableOpacity
          onPress={() => {
            setForSale(true);
          }}
          style={{
            backgroundColor: "#FFF",
            elevation: 3,
            padding: 45,
            borderRadius: 20,
            alignItems: "center",
            marginTop: 5,
            marginBottom: 25,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
          }}
        >
          <AIcon
            name='pluscircleo'
            style={{ color: config.primary_color, fontSize: 80 }}
          />
        </TouchableOpacity>
        {props.forSaleData.length == 0 ? (
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              color: "#000",
              fontSize: 12,
              textAlign: "center",
            }}
          >
            <EIcon
              name='info-with-circle'
              style={{ color: "#0C76EF", fontSize: 15 }}
            />{" "}
            Click to add gig you want to put up for sale
          </Text>
        ) : null}
        {props.forSaleData.map((item, index) => {
          return (
            <ProfileCard
              {...item}
              clicked={() => {
                props.setForSale(item);
                props.navigation.navigate("ForSaleDetail");
              }}
            />
          );
        })}
      </View>
    </View>
  );
  const ThirdRoute = () => (
    <View style={styles.scene}>
      <View style={{ marginHorizontal: "7%", paddingTop: "7%" }}>
        <TouchableOpacity
          onPress={() => {
            setAddPortfolio(true);
          }}
          style={{
            backgroundColor: "#FFF",
            elevation: 3,
            padding: 45,
            borderRadius: 20,
            alignItems: "center",
            marginTop: 5,
            marginBottom: 25,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
          }}
        >
          <AIcon
            name='pluscircleo'
            style={{ color: config.primary_color, fontSize: 80 }}
          />
        </TouchableOpacity>
        {props.portfolioData.length == 0 ? (
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              color: "#000",
              fontSize: 12,
              textAlign: "center",
            }}
          >
            <EIcon
              name='info-with-circle'
              style={{ color: "#0C76EF", fontSize: 15 }}
            />{" "}
            Click to add your previous work to your profile
          </Text>
        ) : null}
        {props.portfolioData.map((item, index) => {
          return (
            <ProfileCard
              {...item}
              clicked={() => {
                props.setPortfolio(item);
                props.navigation.navigate("PortfolioDetail");
              }}
            />
          );
        })}
      </View>
    </View>
  );
  const FourthRoute = () => (
    <View style={styles.scene}>
      <View style={{ marginHorizontal: "7%" }}>
        {props.profile.userProfile?.languages_known ? (
          renderlanguagesKnown()
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 15,
                  color: "#545455",
                  paddingLeft: 5,
                  paddingLeft: 5,
                  marginTop: 10,
                }}
              >
                Languages known
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setknownLanguagesBool(true);
                }}
              >
                <MIcon
                  name='pencil'
                  style={{ color: "#318EF8", fontSize: 20 }}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                setknownLanguagesBool(true);
              }}
              style={{
                padding: 12,
                elevation: 2,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                backgroundColor: "#FFF",
                borderRadius: 10,
                marginBottom: 40,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#AAAAAA",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Add languages known
              </Text>
            </TouchableOpacity>
          </View>
        )}

        {props.profile.userProfile?.communication_preference ? (
          renderCommunicationPreference()
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 15,
                  color: "#545455",
                  paddingLeft: 5,
                  paddingLeft: 5,
                  marginTop: 10,
                }}
              >
                Communication Preference
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setCommunication(true);
                }}
              >
                <MIcon
                  name='pencil'
                  style={{ color: "#318EF8", fontSize: 20 }}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                setCommunication(true);
              }}
              style={{
                padding: 12,
                elevation: 2,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                backgroundColor: "#FFF",
                borderRadius: 10,
                marginBottom: 40,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#AAAAAA",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Add communication preference
              </Text>
            </TouchableOpacity>
          </View>
        )}

        {props.profile.userProfile?.insights ? (
          renderRandomInsights()
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 15,
                  color: "#545455",
                  paddingLeft: 5,
                  paddingLeft: 5,
                  marginTop: 10,
                }}
              >
                Random Insights about me
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setInsights(true);
                }}
              >
                <MIcon
                  name='pencil'
                  style={{ color: "#318EF8", fontSize: 20 }}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                setInsights(true);
              }}
              style={{
                padding: 12,
                elevation: 2,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                backgroundColor: "#FFF",
                borderRadius: 10,
                marginBottom: 40,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#AAAAAA",
                  fontFamily: "Roboto-Regular",
                }}
              >
                Add randon insights about you
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 15,
                color: "#545455",
                paddingLeft: 5,
                paddingLeft: 5,
                marginTop: 10,
              }}
            >
              Connect with me
            </Text>
            <TouchableOpacity
              onPress={() => {
                setSocialLinksModel(true);
              }}
            >
              <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={() => {
              setSocialLinksModel(true);
            }}
            style={{
              padding: 12,
              elevation: 2,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              backgroundColor: "#FFF",
              borderRadius: 10,
              marginBottom: 40,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: "#AAAAAA",
                fontFamily: "Roboto-Regular",
              }}
            >
              Add social media links and other.
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    Basicinfo: FirstRoute,
    Forsale: SecondRoute,
    Portfolio: ThirdRoute,
    Aboutme: FourthRoute,
  });
  const renderTabBar = props => (
    <TabBar
      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ color, margin: 8, fontFamily: "Roboto-Bold" }}>
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: "white" }}
      style={{
        backgroundColor: "#FFF",
        shadowOffset: { height: 0, width: 0 },
        shadowColor: "transparent",
        shadowOpacity: 0,
        elevation: 0,
      }}
      tabStyle={{ width: 130 }}
      activeColor={config.primary_color}
      inactiveColor={"#9090A6"}
      scrollEnabled={true}
    />
  );

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingTop: 20,
        }}
      >
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => props.navigation.goBack()}
        >
          <AIcon name='left' style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: "OpenSans-Semibold",
            color: config.primary_color,
          }}
        >
          Edit Profile
        </Text>
      </View>
    );
  };
  const renderSkills = () => {
    return (
      <View style={{ paddingBottom: 15 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              fontSize: 16,
              color: "#000",
              paddingLeft: 5,
              paddingLeft: 5,
            }}
          >
            Known for
          </Text>
          <TouchableOpacity
            onPress={() => {
              setknownForBool(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {props.profile.userProfile?.known_for.map((item, index) => {
            return (
              <View
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  marginVertical: 8,
                  marginHorizontal: 5,
                  borderRadius: 50,
                  borderWidth: 0.7,
                  borderColor: "#707070",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 13,
                    color: "#473BB3",
                  }}
                >
                  {item}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  };
  const renderCommunicationPreference = () => {
    return (
      <View style={{ paddingLeft: 5, paddingBottom: 25 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
              marginBottom: 15,
            }}
          >
            Communication Preference
          </Text>
          <TouchableOpacity
            onPress={() => {
              setCommunication(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 14,
                color: "#473BB3",
              }}
            >
              Vouch Chat
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 14,
                color: "#473BB3",
              }}
            >
              Phone call
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 14,
                color: "#473BB3",
              }}
            >
              Email
            </Text>
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
              }}
            >
              -{"  "}{" "}
              {props.profile.userProfile?.communication_preference["slot1"]}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
              }}
            >
              -{"  "}{" "}
              {props.profile.userProfile?.communication_preference["slot2"]}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
              }}
            >
              -{"  "}{" "}
              {props.profile.userProfile?.communication_preference["slot3"]}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderlanguagesKnown = () => {
    return (
      <View style={{ paddingVertical: 20, paddingLeft: 5 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
              marginBottom: 5,
            }}
          >
            Languages known
          </Text>
          <TouchableOpacity
            onPress={() => {
              setknownLanguagesBool(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View>
            {props.profile.userProfile?.languages_known.map(item => {
              return (
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 14,
                    color: "#473BB3",
                  }}
                >
                  {item.name}
                </Text>
              );
            })}
          </View>
          <View style={{ paddingLeft: 10 }}>
            {props.profile.userProfile?.languages_known.map(item => {
              return (
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: 14,
                    color: "#464646",
                  }}
                >
                  -{"  "} {item.strength}
                </Text>
              );
            })}
          </View>
        </View>
      </View>
    );
  };
  const renderRandomInsights = () => {
    return (
      <View style={{ paddingLeft: 5, paddingBottom: 25 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
              marginBottom: 15,
            }}
          >
            Random Insights about me
          </Text>
          <TouchableOpacity
            onPress={() => {
              setInsights(true);
            }}
          >
            <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 16,
            color: "#473BB3",
            marginBottom: 5,
          }}
        >
          How would you eat an elephant?
        </Text>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 14,
            color: "#464646",
            marginBottom: 15,
          }}
        >
          {props.profile.userProfile?.insights["text1"]}
        </Text>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 16,
            color: "#473BB3",
            marginBottom: 5,
          }}
        >
          What superhero character are you?
        </Text>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 14,
            color: "#464646",
            marginBottom: 15,
          }}
        >
          {props.profile.userProfile?.insights["text2"]}
        </Text>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 16,
            color: "#473BB3",
            marginBottom: 5,
          }}
        >
          What would you do if you win a lottery?
        </Text>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 14,
            color: "#464646",
            marginBottom: 15,
          }}
        >
          {props.profile.userProfile?.insights["text3"]}
        </Text>
      </View>
    );
  };

  if (props.isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size='large' color={config.primary_color} />
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <StatusBar backgroundColor={config.primary_color} />
      <ScrollView showsVerticalScrollIndicator={false}>
        {renderHeader()}
        <Text
          style={{
            fontFamily: "OpenSans-SemiBold",
            fontSize: 26,
            textAlign: "center",
            marginTop: 35,
            marginBottom: 25,
            color: "#000",
          }}
        >
          {props.profile.userProfile?.company_name
            ? props.profile.userProfile?.company_name
            : `${props.profile.userProfile.firstname} ${props.profile.userProfile.lastname}`}
        </Text>

        <TabView
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          style={{
            shadowOffset: { height: 0, width: 0 },
            shadowColor: "transparent",
            shadowOpacity: 0,
            elevation: 0,
          }}
        />
      </ScrollView>

      {/* social media links model  */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={sociallinksmodel}
        onDismiss={() => {
          setSocialLinksModel(false);
        }}
      >
        <LinkSocials
          {...props}
          cameBack={() => {
            setSocialLinksModel(false);
          }}
          onClose={data => {
            props.editProfile(null, "social_links", data);
            setSocialLinksModel(false);
          }}
        />
      </Modal>

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={img}
        onDismiss={() => {
          setImg(false);
        }}
      >
        <AddPhoto
          cameBack={() => {
            setImg(false);
          }}
          onClose={data => {
            props.editProfile(data, imageTypeName, null);
            setImg(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={forsale}
        onDismiss={() => {
          setForSale(false);
        }}
      >
        <AddForSale
          cameBack={() => {
            setForSale(false);
          }}
          onClose={(
            data,
            milestones,
            milestone_mode,
            classification,
            category,
            images
          ) => {
            props.addGigForSale(
              data,
              milestones,
              milestone_mode,
              classification,
              category,
              images
            );
            setForSale(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={addPortfolio}
        onDismiss={() => {
          setAddPortfolio(false);
        }}
      >
        <AddPortfolio
          cameBack={() => {
            setAddPortfolio(false);
          }}
          onClose={(data, images) => {
            props.addNewPortfolio(data, images);
            setAddPortfolio(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={livingInBool}
        onDismiss={() => {
          setlivingInBool(false);
        }}
      >
        <LivingIn
          cameBack={() => {
            setlivingInBool(false);
          }}
          onClose={data => {
            props.editProfile(null, "livingIn", data);
            setlivingInBool(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={oneLineBool}
        onDismiss={() => {
          setoneLineBool(false);
        }}
      >
        <OneLineAboutMe
          cameBack={() => {
            setoneLineBool(false);
          }}
          onClose={data => {
            props.editProfile(null, "one_line_about_me", data);
            setoneLineBool(false);
          }}
        />
      </Modal>

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownForBool}
        onDismiss={() => {
          setknownForBool(false);
        }}
      >
        <KnownFor
          skills={props.profile.userProfile?.known_for}
          cameBack={() => {
            setknownForBool(false);
          }}
          onClose={data => {
            props.editProfile(null, "known_for", data);
            setknownForBool(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownForBool}
        onDismiss={() => {
          setknownForBool(false);
        }}
      >
        <KnownFor
          skills={props.profile.userProfile?.known_for}
          cameBack={() => {
            setknownForBool(false);
          }}
          onClose={data => {
            props.editProfile(null, "known_for", data);
            setknownForBool(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownLanguagesBool}
        onDismiss={() => {
          setknownLanguagesBool(false);
        }}
      >
        <LanguagesKnown
          languages={props.profile.userProfile?.languages_known}
          cameBack={() => {
            setknownLanguagesBool(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "languages_known", data);
            setknownLanguagesBool(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={communication}
        onDismiss={() => {
          setCommunication(false);
        }}
      >
        <CommunicationPreferences
          communicationData={
            props.profile.userProfile?.communication_preference
          }
          cameBack={() => {
            setCommunication(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "communication_preference", data);
            setCommunication(false);
          }}
        />
      </Modal>
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={insights}
        onDismiss={() => {
          setInsights(false);
        }}
      >
        <RandomInsights
          insightsData={props.profile.userProfile?.insights}
          cameBack={() => {
            setInsights(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "insights", data);
            setInsights(false);
          }}
        />
      </Modal>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
  imageContainer: {
    borderRadius: 100,
    alignSelf: "center",
    elevation: 3,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "#FFF",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  imageContainer1: {
    borderRadius: 10,
    elevation: 3,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "#FFF",
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    height: 180,
  },
  scene: {
    flex: 1,
  },
});

export default connect(
  state => ({
    profile: { ...state.userProfile },
    ...state.editProfile,
  }),
  {
    editProfile,
    changeActiveTab,
    addGigForSale,
    loadInitiallProfileData,
    addNewPortfolio,
    setPortfolio,
    setForSale,
  }
)(EditProfile);
