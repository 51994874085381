import React, { Component } from 'react';
import LottieView from 'lottie-react-web'
import Anim404 from '../../assets/lottie-animations/404.json'
import { View } from 'react-native';

const Errorpage = () => {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
      <LottieView
            options={{
              animationData: Anim404,
              autoplay: true,
              loop: true,
            }}
          style={{
            width: 300,
            height: 300,
          }}
          />
    </View>
    );
}

export default Errorpage;