//import liraries
import React, {Component, useEffect} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  StatusBar,
  FlatList,
} from 'react-native'
import {connect} from 'react-redux'
import {Icon} from 'native-base'
import config from '../../config'
import {fetchActivities} from '../../state-management/actions/activity.action'
import ActivityCard from '../../components/ActivityCard'
import AIcon from 'react-native-vector-icons/AntDesign';
import moment from 'moment'

const WhoIsUsing = props => {
  useEffect(() => {
    props.fetchActivities()
    return () => {}
  }, [])

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <FlatList
      showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                padding: 20,
              }}>
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => props.navigation.goBack()}>
                <AIcon name='left' style={styles.backButton} />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 22,
                  fontFamily: 'Roboto-Medium',
                  color: config.primary_color,
                }}>
                Who is using Vouch
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                borderTopWidth: 1,
                borderTopColor: '#DCDCDC',
                paddingHorizontal: 20,
              }}>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: '#464646',
                  textAlign: 'center',
                  paddingVertical: 20,
                }}>
                Here are all the verified transactions{'\n'}
                happening through vouch
              </Text>
            </View>
          </View>
        }
        data={props.activities}
        renderItem={({item}) => {
          return <ActivityCard {...item} />
        }}
        keyExtractor={(item, index) => index.toString()}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    height: 30,
    marginRight: 20,
    fontSize:25
  },
})

export default connect(state => ({...state.activities}), {
  fetchActivities,
})(WhoIsUsing)
