import { takeLatest, put, call } from "redux-saga/effects";
import {
  MAKE_REQUEST_PAYMENT,
  REQUEST_PAYMENT_ERROR,
  REQUEST_PAYMENT_SUCCESS,
} from "../constants/constants";
import PaymentsService from "../../services/payments.service";
import logsService from "../../services/logs.service";

var link = null;

const b64toBlob = (base64, type = "application/octet-stream") =>
  fetch(`${base64}`).then(res => res.blob());

const execMakeRequest = async actionData => {
  try {
    const { transaction_details, isPayingFull } = actionData;
    logsService.logCustomEvent("transaction_created", {
      gigCategory: transaction_details.category,
      transactionAmount: transaction_details.total_amount,
      status: transaction_details.order_status,
      noOfMiletones: transaction_details.milestones.length,
      mode: transaction_details.mode,
      requestFrom: transaction_details.request_from,
      toUser: transaction_details.to_user,
    });
    // var params = {
    //   transactionDetails: JSON.stringify(transaction_details),
    // };
    var formData = new FormData();
    transaction_details.Attachments.forEach(async (item, i) => {
      const b = await b64toBlob(item.uri);
      formData.append("Attachments", b);
    });
    delete transaction_details.Attachments;
    formData.append("transactionDetails", JSON.stringify(transaction_details));
    var response = await PaymentsService.requestPayment(formData);
    return response.link;
  } catch (error) {
    // console.log(error);
    throw { message: "Something went wrong" };
  }
};

function* makeRequest(action) {
  try {
    const res = yield call(execMakeRequest, action);
    yield put({ type: REQUEST_PAYMENT_SUCCESS, response: { link: res } });
  } catch (error) {
    yield put({
      type: REQUEST_PAYMENT_ERROR,
      response: { message: error.message },
    });
  }
}

function* watchRequestPayment() {
  yield takeLatest(MAKE_REQUEST_PAYMENT, makeRequest);
}

export default watchRequestPayment;
