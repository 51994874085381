//import liraries
import React, {Component, useState, useEffect, useRef} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,Platform, ActivityIndicator
} from 'react-native'
import {Icon,Tab, Tabs} from 'native-base'
import {connect} from 'react-redux'
import config from '../../config'
import PaymentsTab from '../../components/payment_user/PaymentsTab'
import MessagesTab from '../../components/payment_user/MessagesTab'
import AIcon from 'react-native-vector-icons/AntDesign';
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';
import GigDetails from './GigDetails'
import TopicChat from '../../components/TopicChat'
import {
    loadGigDetails,
  } from '../../state-management/actions/gigdetails.action';

const GigInfo = props => {
  const pagerRef = useRef(null)
  const [loader,setLoader] = useState(true);

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'Details', title: 'Details' },
    { key: 'Chat', title: 'Chat' },
  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Details':
        return <GigDetails {...props} />
      case 'Chat':
        return <TopicChat
            parent_data={{
              thread_id: props.route.params.uid,
            }}
          />
      default:
        return null;
    }
  };
  const renderTabBar = props => (
    <TabBar

      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ fontSize:20,color, margin: 8 ,fontFamily:'Roboto-Bold'}}>
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#FFF',
      shadowOffset: { height: 0, width: 0 }, 
      shadowColor: 'transparent',
      shadowOpacity: 0,
      elevation: 0  }}
      activeColor = {config.primary_color}
      inactiveColor = {'#000'}
    />
  )

  const _renderLazyPlaceholder = ({ route }) => <LazyPlaceholder route={route} />;

  const LazyPlaceholder = ({ route }) => (
    <View style={styles.scene}>
      <Text>Loading {route.title}…</Text>
    </View>
  );  

  useEffect(() => {
    props.loadGigDetails(props.route.params.uid);
    return () => {
    }
  }, [])

  if (props.error) {
    alert(props.error)
  }

  return props.isLoading?
  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
  <ActivityIndicator size="large" color={config.primary_color} />
</View>:(
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <View style={{flexDirection: 'row', padding: 20, paddingBottom: 20}}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <Text
          numberOfLines={1}
          style={{
            fontSize: 22,
            fontFamily: 'OpenSans-Bold',
            color: config.primary_color,
            marginTop:3,
            marginLeft:10
          }}>{props.gigDetails.gig_name}</Text>
        </View>
      <View style={{flex:1}}>
         {/* <PersonHeader {...props.paymentsTab.clickedUser} /> */}
      


<TabView
    lazy={true}
    renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      // initialLayout={initialLayout}
      renderLazyPlaceholder={_renderLazyPlaceholder}
      style={{
        shadowOffset: { height: 0, width: 0 }, 
 shadowColor: 'transparent',
 shadowOpacity: 0,
 elevation: 0 
      }}
    />


      </View>
    </SafeAreaView>
  )


}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    fontSize: 25,
    marginTop:5,
    marginRight: 10,
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  default: {
    textTransform: 'capitalize',
  },
  viewPager: {
    flex: 1,
  },
  scene: {
    flex: 1,
  },
})

export default connect(
    state => ({...state.gigDetails}),
    {loadGigDetails},
  )(GigInfo)
