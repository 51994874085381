//import liraries
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React, {Component} from 'react'
import {
  View,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  FlatList,
  Text,
} from 'react-native'
import config from '../../config'
import ProtectedPaymentsCard from './ProtectedPaymentsCard'

class InProgressTab extends Component {
  constructor (props) {
    super(props)
    this.state = {
      emptyActionPending:false
    }
  }

  componentDidMount(){
    var found=this.props.inProgressPayments.find(e=>e.action_pending_from?.includes(firebase.auth().currentUser.uid));
    this.setState({emptyActionPending:!found})
  }
  renderPayment = (item, index) => {
    return this.props?.showOnlyPending?item?.action_pending_from?.includes(firebase.auth().currentUser.uid)?(
      <ProtectedPaymentsCard
        {...item}
        color={config.primary_color}
        clicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.id}`})
        }}
        chatClicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.uid}`,initiallPage:1})
        }}
      />
    ):null:
    (<ProtectedPaymentsCard
        {...item}
        color={config.primary_color}
        clicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.id}`})
        }}
        chatClicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.uid}`,initiallPage:1})
        }}
      />
    )
  }
  render () {
    return this.state.emptyActionPending && this.props.showOnlyPending ? 
    <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
      <Text style={{fontFamily:'OpenSans-Regular',color:'#828282'}}>No new pending action here.</Text>
    </View>
    :!this.props.showOnlyPending && this.props.inProgressPayments.length == 0?
    <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
      <Text style={{fontFamily:'OpenSans-Regular',color:'#828282'}}>No transactions here yet</Text>
    </View>
    : (
      <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{alignItems: 'center', paddingBottom: '3%', marginTop: 20}}>
          <FlatList
            style={{width: '90%'}}
            data={this.props.inProgressPayments}
            renderItem={({item, index}) => this.renderPayment(item, index)}
            keyExtractor={(item, index) => index.toString()}
            removeClippedSubviews={true}
            initialNumToRender={20}
            maxToRenderPerBatch={10}
          />
        </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
  clicked = () => {
    this.props.navigation.navigate('PaymentResult')
  }
}

// define your styles
const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
})

//make this component available to the app
export default InProgressTab
