import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image as Img,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import moment from 'moment';
import config from '../config';
// import ImageView from 'react-native-image-viewing';
// import FastImage from 'react-native-fast-image';

const MessageBubble = props => {
  const [imageVisible, setImageVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const showImage = (i) =>{
    var image = new Image();
    image.src = i;
    var w = window.open("");
    w.document.write(image.outerHTML);
  }
  return (
    <SafeAreaView style={{backgroundColor: '#f7f7f9', flex: 1}}>
      {props?.me ? (
        <View style={[{alignSelf: 'flex-end'}]}>
          <Text style={[styles.bubbleTime, {textAlign: 'right'}]}>
            {moment(new Date(props.date).toISOString()).format('D-MMM-YY') ==
            moment().format('D-MMM-YY')
              ? 'Today'
              : moment(new Date(props.date).toISOString()).format('LL')}
            {','}
            {props.time}
          </Text>
          <View style={[styles.bubbleContainer, styles.bubbleMe]}>
            {props?.isAttachment ? (
              <TouchableOpacity
                style={{justifyContent: 'flex-end', paddingBottom: 5}}
                onPress={() => {
                  showImage(props.attachments)
                }}>
                <Img
                  source={{uri: props.attachments}}
                  style={{
                    width: '100%',
                    height: 200,
                    resizeMode: 'cover',
                    borderRadius: 8,
                    minWidth: 200,
                  }}
                />
              </TouchableOpacity>
            ) : null}
            <Text
              style={[
                styles.bubbleText,
                {color: props.me ? '#FFF' : config.primary_color},
              ]}>
              {props.message}
            </Text>
          </View>
        </View>
      ) : (
        <View style={[{alignSelf: 'flex-start'}]}>
          <Text style={[styles.bubbleTime, {textAlign: 'left'}]}>
            {moment(new Date(props.date).toISOString()).format('D-MMM-YY') ==
            moment().format('D-MMM-YY')
              ? 'Today'
              : moment(new Date(props.date).toISOString()).format('LL')}
            {','}
            {props.time}
          </Text>
          <View style={[styles.bubbleContainer, styles.bubbleTo]}>
            {props?.isAttachment ? (
              <TouchableOpacity
                onPress={() => {
                  showImage(props.attachments)
                }}>
                <Img
                  source={{uri: props.attachments}}
                  style={{
                    width: 200,
                    height: 200,
                    resizeMode: 'cover',
                    borderRadius: 8,
                  }}
                />
              </TouchableOpacity>
            ) : null}
            <Text
              style={[
                styles.bubbleText,
                {color: props.me ? '#FFF' : config.primary_color},
              ]}>
              {props.message}
            </Text>
          </View>
        </View>
      )}
      {/* <ImageView
        images={[
          {
            uri: imageUrl,
          },
        ]}
        imageIndex={0}
        visible={imageVisible}
        onRequestClose={() => {
          setImageVisible(false);
        }}
      /> */}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bubbleContainer: {
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 24,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  bubbleMe: {
    backgroundColor: config.primary_color,
    alignSelf: 'flex-end',
  },
  bubbleTo: {
    backgroundColor: '#FFF',
    alignSelf: 'flex-start',
  },
  bubbleText: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
  },
  bubbleTime: {
    color: '#666',
    fontSize: 10,
    fontFamily: 'OpenSans-Regular',
    marginHorizontal: 20,
    marginVertical: 5,
  },
  dateContainer: {
    padding: 5,
    borderRadius: 30,
    marginVertical: 10,
    marginHorizontal: 10,
    alignSelf: 'center',
    backgroundColor: '#f0f0f0',
    elevation: 4,
  },
  dateText: {fontFamily: 'Roboto-Medium', fontSize: 10, color: '#000'},
});

export default MessageBubble;
