import React, { Component, useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  StatusBar,
  ActivityIndicator,
  Share,
  Linking,
} from "react-native";
import moment from "moment";
import config from "../../../../config";
import AIcon from "react-native-vector-icons/AntDesign";
import { connect } from "react-redux";
import {
  loadProfile,
  loadProfileByKey,
  createUserchatThread,
  resetProfile,
} from "../../../../state-management/actions/userprofile.action";
import ProfileBadge from "../../../../assets/images/profileBadge";
import CheckMark from "../../../../assets/images/CheckMark";
import ExpressIntrestImage from "../../../../assets/images/expressIntrestImage";
// import TrustScoreComponent from "../../../../components/Profile/TrustScoreComponent";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { setContactInfo } from "../../../../state-management/actions/category.action";
import AboutMeComponent from "../../../../components/Profile/AboutMeComponent";
// import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { resetFloatingData } from "../../../../state-management/actions/floating.action";
import LottieView from "lottie-react-web";
import Anim404 from "../../../../assets/lottie-animations/404.json";
import ProfileCard from "../../../../components/Profile/ProfileCard";
import ReviewsTab from "../../../../components/Profile/ReviewsTab";
import logsService from "../../../../services/logs.service";
import {
  addGigForSale,
  addNewPortfolio,
  editProfile,
  setPortfolio,
  setForSale,
} from "../../../../state-management/actions/editprofile.action";
import AddForSale from "../../../../components/Profile/AddForSale";
import AddPortfolio from "../../../../components/Profile/AddPortfolio";
import { Modal } from "react-native-paper";
import KnownFor from "../../../../components/Profile/KnownFor";
import CommunicationPreferences from "../../../../components/Profile/CommunicationPreferences";
import RandomInsights from "../../../../components/Profile/RandomInsights";
import LanguagesKnown from "../../../../components/Profile/LanguagesKnown";
import MIcon from "react-native-vector-icons/MaterialCommunityIcons";
import EIcon from "react-native-vector-icons/Entypo";
import FIcon from "react-native-vector-icons/Feather";
import AwesomeAlert from "react-native-awesome-alerts";
import * as Clipboard from "expo-clipboard";
import { logout } from "../../../../state-management/actions/auth.action";
import { SafeAreaViewStyle } from "../../styles/constant";
import { profileImage } from "../../styles/Userprofile.styles";
import Header from "../../../../reuseable/Header";

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const newUserProfile = props => {
  const [owner, setOwner] = useState(false);
  const pagerRef = React.useRef(null);
  const [knownForBool, setknownForBool] = useState(false);
  const [ReviewsTabBool, setReviewsTabBool] = useState(false);
  const [AboutMeComponentBool, setAboutMeComponentBool] = useState(false);
  const [knownLanguagesBool, setknownLanguagesBool] = useState(false);
  const [insights, setInsights] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [showShortCode, setShowShortCode] = useState(false);
  const [islogin, setIslogin] = useState(false);
  //for message purpose
  const [toUserId, setToUserId] = useState("");

  //usestate for sale state
  const [forsalemodel, setForSaleModel] = useState(false);
  const [forsalemodel2, setForSaleModel2] = useState(false);

  // usestate for PortfolioModel
  const [portfoliomodel, setPortfolioModel] = useState(false);
  const [portfoliomodel2, setPortfolioModel2] = useState(false);

  const [openSocialModel, setOpenSocialModel] = useState(false);
  const [sociallinks, setSocialLinks] = useState({});
  const [t2, setT2] = useState(false);

  useEffect(() => {
    if (!props.navigation.canGoBack()) {
      props.loadProfile();
    }
    if (props.route.params?.showShortCode) {
      setShowShortCode(props.route.params?.showShortCode);
    }
    props.resetFloatingData();
    props.loadProfileByKey({ key: "username", ...props.route.params });
    // setIslogin(props.auth.isAuthenticated);
    return () => {
      props.resetProfile();
    };
  }, []);

  useEffect(() => {
    setSocialLinks(props.requestedProfile?.social_links);
  }, [props.requestedProfile?.social_links]);

  useEffect(() => {
    const currentUser = async () => {
      const UID = await firebase.auth().currentUser.uid;
      if (UID == props.requestedProfile.id) {
        setOwner(true);
      } else {
        setOwner(false);
      }
    };
    currentUser();
  }, [props.requestedProfile.id]);

  if (props.success) {
    props.resetProfile();
    if (props.route.params.hasOwnProperty("changeTab")) {
      props.route.params.changeTab();
      props.navigation.goBack();
    }
    props.navigation.navigate("PaymentUser", {
      uid: toUserId,
      subRoute: "Messages",
      showAlert: props.chatcreatedFlag,
    });
  }

  if (props.isLoading || props.editProfile.isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size='large' color={config.primary_color} />
      </View>
    );
  }

  if (props.error) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <LottieView
          options={{
            animationData: Anim404,
            autoplay: true,
            loop: true,
          }}
          style={styles.animationSize}
        />
      </View>
    );
  }

  // ForSaleModel

  const ForSaleModel = data => {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header props={data} title='For Sale' />
        <View style={{ marginHorizontal: "7%", flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {owner ? (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    setForSaleModel2(true);
                  }}
                  style={{
                    backgroundColor: "#FFF",
                    marginHorizontal: "5%",
                    paddingVertical: "10%",
                    paddingHorizontal: "5%",
                    marginTop: 10,
                    elevation: 3,
                    //fff
                    borderRadius: 20,
                    alignItems: "center",
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                  }}
                >
                  <AIcon
                    name='pluscircleo'
                    style={{ color: config.primary_color, fontSize: 80 }}
                  />
                </TouchableOpacity>
                {data.forSaleData.length == 0 ? (
                  <Text
                    style={{
                      fontFamily: "OpenSans-Regular",
                      color: "#000",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    <EIcon
                      name='info-with-circle'
                      style={{ color: "#0C76EF", fontSize: 15 }}
                    />{" "}
                    Click to add gig you want to put up for sale
                  </Text>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      Clipboard.setString(
                        `https://app.iamvouched.com/${data.requestedProfile.username}/store`
                      );
                      Share.share({
                        message: `https://app.iamvouched.com/${data.requestedProfile.username}/store`,
                      });
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 40,
                      marginTop: 5,
                    }}
                  >
                    <AIcon
                      name='sharealt'
                      style={{ color: "#0C76EF", fontSize: 30 }}
                    />
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        color: "#0C76EF",
                        fontSize: 16,
                        textAlign: "center",
                        marginLeft: 10,
                      }}
                    >
                      Share your store with customer
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            ) : (
              // <TouchableOpacity
              //   onPress={() => {
              //     Share.share({
              //       message: `https://app.iamvouched.com/${props.requestedProfile.username}/store`,
              //     });
              //   }}
              //   style={{
              //     flexDirection: "row",
              //     alignItems: "center",
              //     justifyContent: "center",
              //     marginBottom: 40,
              //     marginTop: 5,
              //   }}
              // >
              //   <AIcon
              //     name='sharealt'
              //     style={{ color: "#0C76EF", fontSize: 30 }}
              //   />
              //   <Text
              //     style={{
              //       fontFamily: "Roboto-Regular",
              //       color: "#0C76EF",
              //       fontSize: 16,
              //       textAlign: "center",
              //       marginLeft: 10,
              //     }}
              //   >
              //     Share
              //   </Text>
              // </TouchableOpacity>
              <View
                style={{
                  height: "292px",
                  backgroundColor: "#F5EBFF",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textDecorationLine: "underline",
                    marginTop: "7.6px",
                  }}
                >
                  BUY WITH CONFIDENCE
                </Text>
                <View
                  style={{
                    marginTop: "16.8px",
                    marginBottom: "16px",
                  }}
                >
                  <ProfileBadge style={{ alignSelf: "center" }} />
                </View>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontStyle: "italic",
                    marginBottom: "8px",
                  }}
                >
                  {props.requestedProfile.company_name
                    ? props.requestedProfile.company_name
                    : `${props.requestedProfile?.firstname} ${props.requestedProfile?.lastname}`}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    marginBottom: "8px",
                    fontWeight: "400",
                  }}
                >
                  Offers money back guarantee backed by vouch
                </Text>

                <View
                  style={{
                    paddingLeft: isMobile ? 10 : 45,
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      // marginLeft: "24px",
                      marginRight: isMobile ? 1 : 10,
                    }}
                  >
                    <CheckMark
                      style={{
                        alignSelf: "center",
                        innerWidth: 10,
                        innerHeight: 10,
                      }}
                    />
                  </Text>
                  <Text
                    style={{
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: "bold",
                      paddingBottom: 20,
                      marginRight: isMobile ? 5 : 7.5,
                      color: "#481380",
                      verticalAlign: "middel",
                    }}
                  >
                    Full refund
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#481380",
                      marginRight: 30,
                      marginTop: 2,
                    }}
                  >
                    if you do not receive your order
                  </Text>
                </View>
                <View
                  style={{
                    paddingLeft: isMobile ? 10 : 45,
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      // marginLeft: "24px",
                      marginRight: isMobile ? 1 : 10,
                    }}
                  >
                    <CheckMark
                      style={{
                        alignSelf: "center",
                        innerWidth: 10,
                        innerHeight: 10,
                      }}
                    />
                  </Text>
                  <Text
                    style={{
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: "bold",
                      paddingBottom: 20,
                      marginRight: isMobile ? 5 : 7.5,
                      color: "#481380",
                      verticalAlign: "middel",
                    }}
                  >
                    Full or partial
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#481380",
                      marginRight: isMobile ? -26 : 30,
                      marginTop: 2,
                    }}
                  >
                    refund if the item is not as described
                  </Text>
                </View>
              </View>
            )}
            {props.forSaleData.map((item, index) => {
              return (
                <ProfileCard
                  {...item}
                  clicked={() => {
                    data.setForSale(item);
                    data.navigation.navigate("ForSaleDetail", {
                      mobile: data.requestedProfile.mobile,
                    });
                  }}
                />
              );
            })}
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  };

  // For Potfolio

  const PortfolioModel = data => {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header props={data} title='Portfolio' />
        <View style={{ flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {owner ? (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    setPortfolioModel2(true);
                  }}
                  style={{
                    backgroundColor: "#FFF",
                    marginHorizontal: "5%",
                    paddingVertical: "10%",
                    paddingHorizontal: "5%",
                    marginTop: 10,
                    elevation: 3,
                    //fff
                    borderRadius: 20,
                    alignItems: "center",
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    marginBottom: 20,
                  }}
                >
                  <AIcon
                    name='pluscircleo'
                    style={{ color: config.primary_color, fontSize: 80 }}
                  />
                </TouchableOpacity>
                {data.portfolioData.length == 0 ? (
                  <Text
                    style={{
                      fontFamily: "OpenSans-Regular",
                      color: "#000",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    <EIcon
                      name='info-with-circle'
                      style={{ color: "#0C76EF", fontSize: 15 }}
                    />{" "}
                    Click to add your previous work to your profile
                  </Text>
                ) : null}
              </View>
            ) : null}
            {data.portfolioData.map((item, index) => {
              return (
                <ProfileCard
                  {...item}
                  clicked={() => {
                    data.setPortfolio(item);
                    data.navigation.navigate("PortfolioDetail", {
                      mobile: data.requestedProfile.mobile,
                    });
                  }}
                />
              );
            })}
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  };

  const renderSkills = () => {
    return (
      <View style={{ paddingVertical: 9, alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
          onPress={() => {
            setknownForBool(true);
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 14,
              color: "#464646",
            }}
          >
            Known for
          </Text>
          {/* {owner ? (
            <TouchableOpacity
              onPress={() => {
                setknownForBool(true);
              }}
            >
              <MIcon
                name='pencil'
                style={{ color: "#318EF8", fontSize: 16, paddingLeft: 10 }}
              />
            </TouchableOpacity>
          ) : null} */}
        </View>
        {props.requestedProfile?.known_for ? (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              paddingHorizontal: "5%",
              justifyContent: "center",
            }}
          >
            {props.requestedProfile?.known_for.map((item, index) => {
              return (
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 13,
                    color: "#464646",
                    padding: 5,
                  }}
                >
                  #{item}
                </Text>
              );
            })}
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };
  return (
    <SafeAreaView style={SafeAreaViewStyle}>
      <StatusBar backgroundColor={config.primary_color} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={pagerRef}
        onContentSizeChange={() => {
          if (props.route.params?.store) {
            // console.log(`getting the route of ${props.route.params?.store} `);
            setForSaleModel(true);
            // setIndex(props.route.params?.tab ? props.route.params?.tab : 1);
            // pagerRef.current.scrollTo({ y: 750, animated: true });
          }
        }}
      >
        <View style={{ height: 250, position: "relative" }}>
          <ImageBackground
            source={
              props.requestedProfile?.cover_image_path
                ? { uri: props.requestedProfile?.cover_image_path }
                : require("../../../../assets/images/profile-image-placeholder.png")
            }
            style={styles.image}
          >
            <View
              style={{
                flex: 1,
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingHorizontal: 10,
                paddingTop: 20,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => props.navigation.goBack()}
                activeOpacity={0.6}
                style={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                  padding: 7,
                  borderRadius: 100,
                }}
              >
                <AIcon name='left' style={[styles.backButton]} />
              </TouchableOpacity>
              {owner ? (
                <TouchableOpacity
                  onPress={() => props.logout()}
                  activeOpacity={0.6}
                  style={{
                    backgroundColor: config.primary_color,
                    padding: 7,
                    borderRadius: 100,
                  }}
                >
                  <MIcon name='power' style={[styles.backButton]} />
                </TouchableOpacity>
              ) : null}
            </View>
          </ImageBackground>
        </View>
        {owner ? (
          <View
            style={{
              alignSelf: "flex-end",
              marginTop: -20,
              marginRight: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("EditProfile");
                // props.navigation.navigate("NewUserProfile");
              }}
              style={{
                borderRadius: 10,
                flexDirection: "row",
                elevation: 4,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                backgroundColor: "#FFF",
                alignItems: "center",
                alignSelf: "flex-start",
                width: 90,
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "center",
                paddingVertical: 8,
              }}
            >
              <AIcon
                name='setting'
                style={{ color: config.primary_color, fontSize: 20 }}
              />
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 10,
                  color: "#545455",
                }}
              >
                {"  "}Edit Profile
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
        <View style={{ alignItems: "center" }}>
          <Image
            source={
              props.requestedProfile?.profile_image_path
                ? { uri: props.requestedProfile?.profile_image_path }
                : require("../../../../assets/images/profile-image-placeholder.png")
            }
            style={profileImage}
          ></Image>
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontFamily: "OpenSans-SemiBold",
                fontSize: 18,
                color: "#000",
              }}
            >
              {props.requestedProfile.company_name
                ? props.requestedProfile.company_name
                : `${props.requestedProfile?.firstname} ${props.requestedProfile?.lastname}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                fontFamily: "OpenSans-SemiBold",
                marginTop: 10,
                fontSize: 14,
                color: "#000",
              }}
            >
              {props.requestedProfile?.livingIn
                ? props.requestedProfile?.livingIn
                : "Location not provided"}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                fontFamily: "OpenSans-SemiBold",
                marginTop: 10,
                fontSize: 14,
                color: "#000",
              }}
            >
              Vouched since
              <Text
                style={{
                  color: "#747373",
                  fontFamily: "OpenSans-Regular",
                  marginTop: 11,
                  fontSize: 12,
                  marginLeft: 10,
                }}
              >
                {`   ${moment(props.requestedProfile?.createdAt).format(
                  "MMM YYYY"
                )}`}
              </Text>
            </Text>

            <Text
              numberOfLines={3}
              style={{
                fontFamily: "Roboto-Regular",
                fontStyle: "italic",
                marginTop: 15,
                fontSize: 14,
                color: "#545455",
                paddingHorizontal: 25,
                textAlign: "center",
              }}
              ellipsizeMode='tail'
            >
              {props.requestedProfile?.one_line_about_me
                ? props.requestedProfile?.one_line_about_me
                : null}
            </Text>
          </View>
          {renderSkills()}
        </View>
        <View
          style={{
            backgroundColor: "#FFF",
            marginHorizontal: "5%",
            borderRadius: 20,
            paddingVertical: "3%",
            paddingHorizontal: "4%",
            marginTop: 10,
            // shadowColor: "#000",
            // shadowOffset: {
            //   width: 0,
            //   height: 0,
            // },
            // shadowOpacity: 0.1,
            // shadowRadius: 10,
            // elevation: 8,
            // shadowColor: "#0e0d0d",
          }}
        >
          <ProfileBadge style={{ alignSelf: "center" }} />
        </View>
        <View
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginVertical: 10,
          }}
        >
          <TouchableOpacity
            style={styles.optionsButton}
            onPress={() => {
              setReviewsTabBool(true);
            }}
          >
            <Text style={styles.optionsButtonText}>Reviews</Text>
          </TouchableOpacity>
          {props?.forSaleData?.length != 0 || owner ? (
            <TouchableOpacity
              style={styles.optionsButton}
              onPress={() => {
                setForSaleModel(true);
              }}
            >
              <Text style={styles.optionsButtonText}>For Sale</Text>
            </TouchableOpacity>
          ) : null}

          {props?.portfolioData?.length != 0 || owner ? (
            <TouchableOpacity
              style={styles.optionsButton}
              onPress={() => {
                setPortfolioModel(true);
              }}
            >
              <Text style={styles.optionsButtonText}>Portfolio</Text>
            </TouchableOpacity>
          ) : null}

          <TouchableOpacity
            style={styles.optionsButton}
            onPress={() => {
              setAboutMeComponentBool(true);
            }}
          >
            <Text style={styles.optionsButtonText}>About Me</Text>
          </TouchableOpacity>
        </View>

        <View>
          {!owner ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  logsService.logCustomEvent("express_intrest_clicked", {});
                  setToUserId(props.requestedProfile?.id);
                  props.createUserchatThread(props.requestedProfile?.id);
                }}
                style={{
                  flexDirection: "row",
                  paddingHorizontal: 20,
                  paddingVertical: 8,
                  borderRadius: 100,
                  backgroundColor: config.primary_color,
                }}
              >
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    color: "#FFF",
                    alignSelf: "center",
                    marginRight: 10,
                    fontSize: 16,
                  }}
                >
                  Say hello
                </Text>
                <ExpressIntrestImage />
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          {sociallinks?.call ? (
            <FIcon
              onPress={() => {
                Linking.openURL(
                  `tel:${sociallinks?.call}`,
                  );
              }}
              name='phone-call'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='phone-call'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.facebook ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.facebook.com/${sociallinks?.facebook}`,
                  '_blank'
                );
              }}
              name='facebook'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='facebook'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.instagram ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.instagram.com/${sociallinks?.instagram}`,
                  '_blank'
                );
              }}
              name='instagram'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='instagram'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.twitter ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.twitter.com/${sociallinks?.twitter}`,
                  '_blank'
                );
              }}
              name='twitter'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='twitter'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.linkedin ? (
            <FIcon
              onPress={() => {
                window.open(
                  sociallinks?.linkedin,
                  '_blank'
                  );
              }}
              name='linkedin'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='linkedin'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          {sociallinks?.web ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://${sociallinks?.web}`,
                  '_blank'
                  );
              }}
              name='globe'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='globe'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.mail ? (
            <FIcon
              onPress={() => {
                window.open(
                  `mailto:${sociallinks?.mail}`,
                  '_blank'
                  );
              }}
              name='mail'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='mail'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.youtube ? (
            <FIcon
              onPress={() => {
                window.open(
                  sociallinks?.youtube,
                  '_blank'
                  );
              }}
              name='youtube'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='youtube'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              Clipboard.setString(
                `app.iamvouched.com/${props.requestedProfile.username}`
              );
              setT2(true);
            }}
            style={{ paddingLeft: 1 }}
          >
            {t2 ? (
              <View
                style={{
                  padding: 5,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: 10,
                }}
              >
                <Text style={{ fontSize: 10 }}>Copied!</Text>
              </View>
            ) : (
              <View>
                <Text
                  numberOfLines={1}
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 13,
                    flex: 0.9,
                    color: "#473BB3",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  app.iamvouched.com/{props.requestedProfile.username}
                  <AIcon
                    name='copy1'
                    style={{ fontSize: 18, color: "#545455", marginLeft: 5 }}
                  />
                </Text>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>

      {/****** models start here *******/}

      {/* forsale model */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={forsalemodel}
        onDismiss={() => {
          setForSaleModel(false);
        }}
      >
        <ForSaleModel
          cameBack={() => {
            setForSaleModel(false);
          }}
          {...props}
        />
      </Modal>
      {/* forsale model 2*/}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={forsalemodel2}
        onDismiss={() => {
          setForSaleModel2(false);
        }}
      >
        <AddForSale
          cameBack={() => {
            setForSaleModel2(false);
          }}
          onClose={(
            data,
            milestones,
            milestone_mode,
            classification,
            category,
            images
          ) => {
            props.addGigForSale(
              data,
              milestones,
              milestone_mode,
              classification,
              category,
              images
            );
            setForSaleModel2(false);
          }}
        />
      </Modal>

      {/* PortfolioModel */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={portfoliomodel}
        onDismiss={() => {
          setPortfolioModel(false);
        }}
      >
        <PortfolioModel
          cameBack={() => {
            setPortfolioModel(false);
          }}
          {...props}
        />
      </Modal>

      {/* PortfolioModel 2 */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={portfoliomodel2}
        onDismiss={() => {
          setPortfolioModel2(false);
        }}
      >
        <AddPortfolio
          cameBack={() => {
            setPortfolioModel2(false);
          }}
          onClose={(data, images) => {
            props.addNewPortfolio(data, images);
            setPortfolioModel2(false);
          }}
        />
      </Modal>

      {/* KonwnFor Model */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownForBool}
        onDismiss={() => {
          setknownForBool(false);
        }}
      >
        <KnownFor
          skills={props.requestedProfile?.known_for}
          cameBack={() => {
            setknownForBool(false);
          }}
          onClose={data => {
            props.editProfile(null, "known_for", data);
            setknownForBool(false);
          }}
        />
      </Modal>

      {/* ReviewsTab Model */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={ReviewsTabBool}
        onDismiss={() => {
          setReviewsTabBool(false);
        }}
      >
        <ReviewsTab
          {...props.requestedProfile}
          reviewData={props.review_data}
          cameBack={() => {
            setReviewsTabBool(false);
          }}
          complementData={props.complement_data}
          recommendationData={props.recommendation_data}
          reqRecommFlow={() => {
            props.navigation.navigate("Category", {
              title: "Go ahead, select a\nCategory",
              description: "What was your past transaction about ?",
              flow_code: 1,
            });
          }}
          routeToChat={id => {
            setToUserId(id);
            props.createUserchatThread(id);
          }}
        />
      </Modal>

      {/**** AboutMeComponent ****/}

      {/* AboutMe */}
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={AboutMeComponentBool}
        onDismiss={() => {
          setAboutMeComponentBool(false);
        }}
      >
        <AboutMeComponent
          {...props.requestedProfile}
          editLanguagesKnown={() => {
            setknownLanguagesBool(true);
          }}
          cameBack={() => {
            setAboutMeComponentBool(false);
          }}
          commPrefs={() => {
            setCommunication(true);
          }}
          randomInsights={() => {
            setInsights(true);
          }}
          connectWithMe={() => {
            setOpenSocialModel(true);
          }}
        />
      </Modal>

      {/* editLanguagesKnown */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownLanguagesBool}
        onDismiss={() => {
          setknownLanguagesBool(false);
        }}
      >
        <LanguagesKnown
          languages={props.requestedProfile?.languages_known}
          cameBack={() => {
            setknownLanguagesBool(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "languages_known", data);
            setknownLanguagesBool(false);
          }}
        />
      </Modal>

      {/* commPrefs */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={communication}
        onDismiss={() => {
          setCommunication(false);
        }}
      >
        <CommunicationPreferences
          communicationData={props.requestedProfile?.communication_preference}
          cameBack={() => {
            setCommunication(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "communication_preference", data);
            setCommunication(false);
          }}
        />
      </Modal>

      {/* RandomInsights */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={insights}
        onDismiss={() => {
          setInsights(false);
        }}
      >
        <RandomInsights
          insightsData={props.requestedProfile?.insights}
          cameBack={() => {
            setInsights(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "insights", data);
            setInsights(false);
          }}
        />
      </Modal>
      <AwesomeAlert
        show={showShortCode}
        title=''
        message=''
        messageStyle={{ textAlign: "center" }}
        customView={
          <View
            style={{
              alignItems: "center",
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 20,
              paddingBottom: 0,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#000",
                lineHeight: 21,
              }}
            >
              Short Code for Badge
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 12,
                color: "#707070",
                marginTop: 15,
              }}
            >
              Place this code wherever you want the plugin to appear on your
              page.
            </Text>
            <Text
              style={{
                paddingVertical: 20,
                backgroundColor: "#F2F2F2",
                marginTop: 15,
                borderColor: "#DCDCDC",
                borderWidth: 1,
                paddingHorizontal: 10,
                color: "#0C76EF",
                fontFamily: "Roboto-Regular",
                fontSize: 12,
                lineHeight: 16,
              }}
            >
              {
                '<div class="fb-like" data-href="https://developers.facebook.com/docs/plugins/" data-width="" data-layout="standard" data-action="like" data-size="small" data-share="true"></div>'
              }
            </Text>
          </View>
        }
        showCancelButton={false}
        showConfirmButton={true}
        confirmText='Copy'
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() => {
          setShowShortCode(false);
        }}
      />
    </SafeAreaView>
  );
};

const styles = {
  image: {
    flex: 1,
    justifyContent: "space-between",
  },
  backButton: {
    color: "#FFF",
    fontSize: 20,
    paddingRight: 0,
  },
  scene: {
    flex: 1,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  optionsButton: {
    margin: 10,
    height: 55,
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 25,
    shadowColor: "#0e0d0d",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 7,
    elevation: 5,
  },
  optionsButtonText: {
    fontFamily: "OpenSans-Regular",
    fontWeight: "bold",
    fontSize: 16,
  },
};

export default connect(
  state => ({
    recentContacts: { ...state.recentContacts },
    editProfile: { ...state.editProfile },
    ...state.userProfile,
    ...state,
  }),
  {
    loadProfile,
    loadProfileByKey,
    createUserchatThread,
    resetProfile,
    setContactInfo,
    resetFloatingData,
    addGigForSale,
    addNewPortfolio,
    editProfile,
    setPortfolio,
    setForSale,
    logout,
  }
)(newUserProfile);
