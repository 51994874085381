import React, {useRef, useEffect, useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import {Icon, Item, Input} from 'native-base';
import config from '../../config';
import PrimaryButton from '../../components/PrimaryButton';
import {connect} from 'react-redux';
import {inviteUser} from '../../state-management/actions/inviteuser.action';
import AIcon from 'react-native-vector-icons/AntDesign';

const InviteUser = props => {
  const [email, setEmail] = useState('');
  const [phno, setPhno] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    var mobile = props.route?.params?.mobile;
    var nameTemp = props.route?.params?.name;
    if (mobile.startsWith('+91')) {
      mobile = mobile.slice(3).replace(/[- )(]/g, '');
    } else if (mobile.length > 10 && mobile.startsWith('91')) {
      mobile = mobile.slice(2).replace(/[- )(]/g, '');
    } else if (mobile.startsWith('0')) {
      mobile = mobile.slice(1).replace(/[- )(]/g, '');
    } else {
      mobile = mobile.replace(/[- )(]/g, '');
    }
    setPhno(mobile);
    if (nameTemp) {
      setName(nameTemp);
    }
    return () => {};
  }, []);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <ScrollView>
        <View style={{flexDirection: 'row', alignItems: 'center', padding: 20}}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}>
            <AIcon name="left" style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 28,
              fontFamily: 'Roboto-Medium',
              color: config.primary_color,
            }}>
            Invite Contact
          </Text>
        </View>
        <View style={{marginHorizontal: '9%'}}>
          <Text
            style={{
              fontFamily: 'OpenSans-SemiBold',
              color: '#464646',
              fontSize: 18,
            }}>
            {name ? name : phno} is not on Vouch
          </Text>
          <Text
            style={{
              fontFamily: 'OpenSans-Regular',
              paddingVertical: 40,
              color: '#464646',
              fontSize: 16,
            }}>
            We will send a link to {name ? name : 'the number'} {'\n'}
            to create a new account on Vouch.{'\n'}Don't worry - it's completely
            FREE
          </Text>
          <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 16,
                color: '#464646',
                paddingLeft: 5,
              }}>
              Email
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 16,
                color: '#AAAAAA',
                paddingLeft: 5,
              }}>
              (Optional)
            </Text>
          </View>
          <Item regular style={{marginTop: 3, borderRadius: 10, height: 50}}>
            <Input
              placeholderTextColor={'#AAAAAA'}
              placeholder="Enter email of the user you want to invite"
              textContentType="name"
              onChangeText={txt => setEmail(txt)}
              value={email}
              style={{fontSize: 14, padding: 15}}
            />
          </Item>
          <View style={{height: 40, width: '95%', paddingTop: 3}} />
          <PrimaryButton
            content={'Proceed'}
            loading={props.isLoading}
            clicked={() => {
              if (/^\d{10}$/.test(props.route.params.mobile)) {
                props.inviteUser(props.route.params.mobile, email);
                if (props.isLoading == false && props.error == '') {
                  if(props.route.params?.flow_code==1){
                    props.navigation.navigate('RecommendationDetails', {
                      name: phno,
                      mobile: phno,
                    });
                  }else{
                  props.navigation.navigate('PaymentDetails', {
                    name: phno,
                    mobile: phno,
                  });
                }
                } else {
                  alert(props.error);
                }
              } else {
                alert('Please enter a valid number');
              }
            }}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginRight: 20,
    marginTop: 5,
    fontSize: 25,
  },
});

export default connect(
  state => ({...state.inviteuser}),
  {inviteUser},
)(InviteUser);
