import React, {Component} from 'react'
import {
  View,
  Text,
} from 'react-native'
import EIcon from 'react-native-vector-icons/FontAwesome'
import {PieChart} from 'react-native-svg-charts'

export default class PieChartComponent extends Component {
    constructor (props) {
      super(props)
      this.state = {}
    }
  
    render () {
      return (
           <View>
               <Text style={{
                            fontFamily: 'Roboto-Regular',
                            color: '#464646',marginBottom:8,
                             fontSize: 18,}}>
                           {this.props.title}
                        </Text>
                    <Text style={{
                            fontSize: 18,
                            fontFamily: 'Roboto-Regular',
                            color: '#464646',}}>
                            {`\u20B9${this.props.data[0].amount+this.props.data[1].amount}`}
                    </Text>
                    {this.props.data[0].amount+this.props.data[1].amount==0?null:
                    <View>
                    <View style={{alignSelf:"flex-end",position:'absolute',bottom:50,right:35}}>
                    <Text style={{
                        fontSize: 14,
                        textAlign:"right",
                        marginBottom:3,
                        fontFamily: 'Roboto-Regular',
                        color: '#464646',}}>
                        {`\u20B9${this.props.data[0].amount}`}
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        textAlign:"right",
                        fontFamily: 'Roboto-Regular',
                        color: '#464646',}}>
                        {this.props.text1}
                    </Text>
                    </View>
                    <PieChart
                    style={{ height: 100,marginVertical:10}}
                    valueAccessor={({ item }) => item.amount}
                    data={this.props.data}
                    spacing={0}
                    outerRadius={'100%'}
                    innerRadius={'60%'}>
                    </PieChart>
                    <View style={{alignSelf:"flex-end",position:'absolute',bottom:25,left:30}}>
                        <Text style={{
                            fontSize: 14,
                            textAlign:"right",
                            marginBottom:3,
                            fontFamily: 'Roboto-Regular',
                            color: '#464646',}}>
                        {`\u20B9${this.props.data[1].amount}`}
                        </Text>
                        <Text style={{
                            fontSize: 10,
                            textAlign:"right",
                            fontFamily: 'Roboto-Regular',
                            color: '#464646',}}>
                            {this.props.text2}
                        </Text>
                    </View>
                    </View>}
                    {this.props.data[0].amount+this.props.data[1].amount==0?null:
                    <View>
                        <View style={{flexDirection:"row",marginTop:15,justifyContent:'flex-end',alignItems:'center'}}>
                        <Text style={{
                                fontSize: 12,
                                textAlign:"right",
                                fontFamily: 'Roboto-Regular',
                                color: this.props.data[1].svg.fill,}}>
                                    {this.props.title=='Payments'?
                                    'Settled to seller bank':
                                'Settled to your bank'}
                        </Text>
                        <EIcon name="circle" style={{color:this.props.data[1].svg.fill,marginLeft:5}} />
                    </View>
                    <View style={{flexDirection:"row",justifyContent:'flex-end',alignItems:'center'}}>
                        <Text style={{
                                fontSize: 12,
                                textAlign:"right",
                                fontFamily: 'Roboto-Regular',
                                color: this.props.data[0].svg.fill,}}>
                                Securely Held by Vouch
                        </Text>
                        <EIcon name="circle" style={{color:this.props.data[0].svg.fill,marginLeft:5}} />
                    </View>
                    </View>
                    }
            </View>
      )
    }
}