import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
} from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import FIcon from "react-native-vector-icons/Feather";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import MIcon from "react-native-vector-icons/MaterialCommunityIcons";
import * as Clipboard from "expo-clipboard";
import config from "../../config";
import { Item, Input } from "native-base";
import miscService from "../../services/misc.service";
import Accordion from "../Accordion";
import Header from "../../reuseable/Header";

const AboutMeComponent = props => {
  const [t2, setT2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [show, setShow] = useState(true);
  const [copied, setCopied] = useState(false);

  const renderlanguagesKnown = () => {
    const styles = {
      fontFamily: "Roboto-Medium",
      fontSize: 14,
      color: "#473BB3",
    };
    return (
      <View
        style={{
          paddingVertical: 10,
          paddingBottom: 20,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
            }}
          >
            Languages Known
          </Text>
          {firebase.auth()?.currentUser?.uid == props?.id ? (
            <TouchableOpacity
              onPress={() => {
                props.editLanguagesKnown();
              }}
            >
              <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
            </TouchableOpacity>
          ) : null}
        </View>
        {props?.languages_known ? (
          <View style={{ flexDirection: "row" }}>
            <View>
              {props?.languages_known.map(item => {
                return <Text style={styles}>{item.name}</Text>;
              })}
            </View>
            <View style={{ paddingLeft: 10 }}>
              {props?.languages_known.map(item => {
                return (
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      fontSize: 14,
                      color: "#464646",
                    }}
                  >
                    -{"  "} {item.strength}
                  </Text>
                );
              })}
            </View>
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };

  const renderCommunicationPreference = () => {
    return (
      <View
        style={{
          paddingVertical: 10,
          paddingBottom: 20,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
          marginTop: 15,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
            }}
          >
            Communication Preference
          </Text>
          {firebase.auth()?.currentUser?.uid == props?.id ? (
            <TouchableOpacity
              onPress={() => {
                props.commPrefs();
              }}
            >
              <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
            </TouchableOpacity>
          ) : null}
        </View>
        {props?.communication_preference ? (
          <View style={{ flexDirection: "row" }}>
            <View>
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 14,
                  color: "#473BB3",
                }}
              >
                Vouch Chat
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 14,
                  color: "#473BB3",
                }}
              >
                Phone call
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 14,
                  color: "#473BB3",
                }}
              >
                Email
              </Text>
            </View>
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 14,
                  color: "#464646",
                }}
              >
                -{"  "} {props?.communication_preference["slot1"]}
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 14,
                  color: "#464646",
                }}
              >
                -{"  "} {props?.communication_preference["slot2"]}
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 14,
                  color: "#464646",
                }}
              >
                -{"  "} {props?.communication_preference["slot3"]}
              </Text>
            </View>
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };

  const renderRandomInsights = () => {
    return (
      <View
        style={{
          paddingVertical: 10,
          paddingBottom: 20,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
          marginTop: 15,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
            }}
          >
            Random Insights about me
          </Text>
          {firebase.auth()?.currentUser?.uid == props?.id ? (
            <TouchableOpacity
              onPress={() => {
                props.randomInsights();
              }}
            >
              <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
            </TouchableOpacity>
          ) : null}
        </View>
        {props?.insights ? (
          <View>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              How would you eat an elephant?
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.insights["text1"]}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              What superhero character are you?
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.insights["text2"]}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              What would you do if you win a lottery?
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.insights["text3"]}
            </Text>
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };

  const SocialLinks = () => {
    return (
      <View
        style={{
          paddingVertical: 10,
          paddingBottom: 20,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
          marginTop: 15,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 15,
              color: "#545455",
            }}
          >
            Connect with me
          </Text>
          {/* {firebase.auth()?.currentUser?.uid == props?.id ? (
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate("EditProfile");
                //TODO : add some logic here..
              }}
            >
              <MIcon name='pencil' style={{ color: "#318EF8", fontSize: 20 }} />
            </TouchableOpacity>
          ) : null} */}
        </View>

        {props?.social_links ? (
          <View>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Email
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.mail
                ? props?.social_links.mail
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Phone
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.call
                ? props?.social_links.call
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Website
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.web
                ? props?.social_links.web
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Facebook Username
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.facebook
                ? props?.social_links.facebook
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Instagram Username
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.instagram
                ? props?.social_links.instagram
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Twitter Username
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.twitter
                ? props?.social_links.twitter
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Linkedin URL
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.linkedin
                ? props?.social_links.linkedin
                : "Not Provided"}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#473BB3",
                marginBottom: 5,
              }}
            >
              Youtube URL
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                marginBottom: 15,
              }}
            >
              {props?.social_links.youtube
                ? props?.social_links.youtube
                : "Not Provided"}
            </Text>
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };

  const renderLink = () => {
    return (
      <View
        style={{
          paddingVertical: 10,
          paddingBottom: 20,
          marginBottom: 10,
          marginTop: 15,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
        }}
      >
        <Text
          style={{
            fontFamily: "Roboto-Medium",
            fontSize: 16,
            color: "#545455",
            paddingBottom: 25,
          }}
        >
          Profile Link
        </Text>
        <TouchableOpacity
          onPress={() => {
            Clipboard.setString(`app.iamvouched.com/${props.username}`);
            setT2(true);
          }}
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <FIcon
            name='external-link'
            style={{
              fontSize: 20,
              color: "#545455",
              marginRight: 10,
              flex: 0.07,
            }}
          />
          <Text
            numberOfLines={1}
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 13,
              flex: 0.9,
              color: "#473BB3",
            }}
          >
            app.iamvouched.com/{props.username}
          </Text>
          <TouchableOpacity
            onPress={() => {
              Clipboard.setString(`app.iamvouched.com/${props.username}`);
              setT2(true);
            }}
            style={{ paddingLeft: 10 }}
          >
            {t2 ? (
              <View
                style={{
                  padding: 5,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: 10,
                }}
              >
                <Text style={{ fontSize: 10 }}>Copied!</Text>
              </View>
            ) : (
              <AIcon name='copy1' style={{ fontSize: 18, color: "#545455" }} />
            )}
          </TouchableOpacity>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header props={props} title='About me' />
      <View style={{ flex: 1 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              backgroundColor: "#F3F3F3",
              marginHorizontal: "5%",
              borderRadius: 20,
              paddingVertical: "5%",
              paddingHorizontal: "5%",
              marginTop: 20,
            }}
          >
            {renderlanguagesKnown()}

            {renderCommunicationPreference()}

            {renderRandomInsights()}

            {SocialLinks()}

            {renderLink()}
            {firebase.auth()?.currentUser?.uid == props?.id ? (
              <Accordion title={"Advanced Developer Options"}>
                <View style={{ paddingVertical: 10 }}>
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 13,
                      color: "#545455",
                      paddingBottom: 15,
                    }}
                  >
                    Api Key
                  </Text>
                  {loading ? (
                    <View
                      style={{
                        width: 120,
                        height: 50,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                      }}
                    >
                      <ActivityIndicator
                        size='small'
                        color={config.primary_color}
                      />
                    </View>
                  ) : token ? (
                    <View>
                      <Item regular>
                        <Input
                          textContentType={"password"}
                          secureTextEntry={show}
                          disabled={true}
                          value={token}
                          style={{ fontSize: 14, padding: 15 }}
                        />
                        {show ? (
                          <MIcon
                            style={{ fontSize: 25, padding: 10 }}
                            onPress={() => setShow(false)}
                            active
                            name='eye-outline'
                          />
                        ) : (
                          <MIcon
                            style={{ fontSize: 25, padding: 10 }}
                            onPress={() => setShow(true)}
                            active
                            name='eye-off-outline'
                          />
                        )}
                      </Item>
                      <TouchableOpacity
                        onPress={() => {
                          setCopied(true);
                          Clipboard.setString(token);
                        }}
                        style={{
                          width: 100,
                          justifyContent: "center",
                          paddingVertical: 10,
                          backgroundColor: copied ? "#06993e" : "#AAA",
                          marginTop: 15,
                          marginBottom: 20,
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto-Regular",
                            fontSize: 12,
                            color: "#FFF",
                          }}
                        >
                          {copied ? "Copied!" : "Copy"}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <TouchableOpacity
                      onPress={() => {
                        setLoading(true);
                        miscService
                          .genrerateApiKey({ grant_type: "client_credentials" })
                          .then(result => {
                            setToken(result.refresh_token);
                            setLoading(false);
                          })
                          .catch(err => {
                            alert("Something went wrong!");
                            setLoading(false);
                          });
                      }}
                      style={{
                        borderRadius: 10,
                        flexDirection: "row",
                        elevation: 4,
                        shadowColor: "#000",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        backgroundColor: "#FFF",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        width: 160,
                        marginLeft: 5,
                        marginTop: 5,
                        justifyContent: "center",
                        paddingVertical: 8,
                      }}
                    >
                      <MIcon
                        name='key'
                        style={{ color: config.primary_color, fontSize: 18 }}
                      />
                      <Text
                        style={{
                          fontFamily: "Roboto-Medium",
                          fontSize: 12,
                          color: "#545455",
                        }}
                      >
                        {"  "}Generate / Fetch Key
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </Accordion>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default AboutMeComponent;
