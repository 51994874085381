import React, {useEffect} from 'react';
import {SafeAreaView, Text, StyleSheet, View, ActivityIndicator} from 'react-native';
import Header from '../../components/Header';
import PrimaryButton from '../../components/PrimaryButton';
import config from '../../config';
import {loadAccount} from '../../state-management/actions/accounts.action';
import {connect} from 'react-redux';

const MyBank = props => {
  // useEffect(()=>{
  //   props.loadAccount()
  // },[])
  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
      props.loadAccount();
    });
    return unsubscribe;
  }, []);

  if(props.isLoading){
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
        <ActivityIndicator size="large" color={config.primary_color} />
      </View>
    );
  }
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <Header title={'My Bank A/c'} navigation={props.navigation} />
      <View style={{padding: 25, paddingHorizontal: 35}}>
        <Text
          style={{
            fontSize: 18,
            color: '#707070',
            fontFamily: 'Roboto-Regular',
          }}>
          To be received
        </Text>
        <Text
          style={{
            fontSize: 20,
            color: '#473BB3',
            marginTop: 10,
            marginBottom: 20,
            fontFamily: 'Roboto-Regular',
          }}>
          {'\u20B9'} {props.moneyToBeEarned}
        </Text>
        <Text
          style={{
            fontSize: 18,
            color: '#707070',
            fontFamily: 'Roboto-Regular',
          }}>
          All funds are credited to your account within 1-3 business days of buyer approval
        </Text>
      </View>
      {props?.accountDetails?.length == 1 ? (
        <View style={{paddingHorizontal: 45, marginTop: 15}}>
          {props.accountDetails[0]?.account_details?.account_type == 'vpa'? (
            <View>
              <Text
                style={{
                  fontFamily: 'Roboto-Medium',
                  fontSize: 16,
                  color: '#464646',
                  paddingBottom: 10,
                }}>
                UPI ID
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: '#828282',
                }}>
                {props.accountDetails[0]?.account_details?.vpa?.address}
              </Text>
            </View>
          ) : (
            <View>
              <Text
                style={{
                  fontFamily: 'Roboto-Medium',
                  fontSize: 16,
                  color: '#464646',
                  paddingBottom: 10,
                }}>
                A/C Number
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: '#828282',
                }}>
                  {props.accountDetails[0]?.account_details?.bank_account?.account_number}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Medium',
                  fontSize: 16,
                  color: '#464646',
                  paddingBottom: 10,
                  marginTop: 25,
                }}>
                IFSC Code
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: '#828282',
                }}>
                {props.accountDetails[0]?.account_details?.bank_account?.ifsc}
              </Text>
            </View>
          )}
        </View>
      ) : (
        <View>
          {props.isLoading ? null : (
            <View
              style={{padding: 15, justifyContent: 'center', marginTop: 25}}>
              <PrimaryButton
                content={'Update your Bank A/C details'}
                clicked={() => {
                  props.navigation.navigate('LinkBankAccount');
                }}
                helperText={
                  <Text style={{fontSize:12,textAlign:'right',marginTop:5,color:'grey',color:'#01ba48',fontWeight:'bold'}}>Recommend, Faster settlements, No limits on settlement amount</Text>
                }
              />
              <Text style={styles.orText}>or</Text>
              <PrimaryButton
                disabled={false}
                content={'Update your UPI ID'}
                clicked={() => {
                  props.navigation.navigate('LinkUPI');
                }}
                helperText={
                  // <Text style={{fontSize:12,textAlign:'right',marginTop:5,color:'grey',color:'#ff0052'}}>Temporarily unavailable</Text>
                  <></>
                }
              />
              <Text
              style={{
                fontSize: 18,
                color: '#707070',
                fontFamily: 'Roboto-Regular',
                padding:15,
                marginTop:35,
              }}>
              Update your UPI ID or Bank A/C details to receive money in your account.
            </Text>
            </View>
          )}
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  orText: {
    textAlign: 'center',
    color: '#707070',
    fontSize: 20,
    marginTop: 15,
  },
});

export default connect(
  state => ({
    ...state.accounts,
  }),
  {
    loadAccount,
  },
)(MyBank);
