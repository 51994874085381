import { takeLatest, put, call } from "redux-saga/effects";
import {
  PHONE_SIGNIN,
  PHONE_ERROR,
  PHONE_SUCCESS,
  AUTH_SUCCESS,
  NAVIGATE_TO_SIGNUP,
} from "../constants/constants";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Platform } from "react-native";
import { FirebaseRecaptchaVerifier } from "expo-firebase-recaptcha";
import userService from "../../services/user.service";
import { dispatch, navigate } from "../../navigation/Navigator";
import config from "../../config";

const auth = firebase.auth;
const firestore = firebase.firestore;

const executeLogin = async (data, captcha) => {
  try {
    var params;
    if (/^\d{10}$/.test(data)) {
      params = {
        key: "mobile",
        value: data,
      };
    } else {
      params = {
        key: "username",
        value: data,
      };
    }
    const result = await userService.getUser(params);

    if (!result) {
      return { code: 400, message: "Username or Phone number not found" };
    } else {
      if (result.hasOwnProperty("virtual_id")) {
        navigate("SignUp", {
          data: { mobile: data },
        });
        return { code: 203 };
      } else {
        var confirmation = null;

        if (Platform.OS == "web") {
          if(config.env == 'sandbox') firebase.auth().settings.appVerificationDisabledForTesting = false;
          else firebase.auth().settings.appVerificationDisabledForTesting = false;
          var container = document.createElement("div");
          container.id = "captcha";
          document.body.appendChild(container);
          var captchaWeb = new firebase.auth.RecaptchaVerifier("captcha", {
            size: "invisible",
            callback: function (token) {
              callback(token);
            },
            "expired-callback": function () {
              callback("");
            },
          });

          confirmation = await auth().signInWithPhoneNumber(
            `+91${result.mobile}`,
            captchaWeb
          );
          document.body.removeChild(container);
        } else {
          var captchaVerifier = new FirebaseRecaptchaVerifier(captcha);
          confirmation = await auth().signInWithPhoneNumber(
            `+91${result.mobile}`,
            captchaVerifier
          );
        }

        if (confirmation?.verificationId)
          return { code: 200, confirm: confirmation, mobile: result.mobile };
        else return { code: 201 };
      }
    }
  } catch (error) {
    console.log(error);
    return {
      code: 400,
      message: "Something went wrong,Please try again later",
    };
  }
};

function* login(action) {
  try {
    const res = yield call(executeLogin, action.data, action.captcha);
    if (res.code == 201) {
      yield put({
        type: AUTH_SUCCESS,
        response: { intro: true, auth: true },
      });
    } else if (res.code == 203) {
      yield put({ type: NAVIGATE_TO_SIGNUP, response: {} });
    } else if (res.code != 200) {
      yield put({ type: PHONE_ERROR, response: { message: res.message } });
    } else {
      yield put({
        type: PHONE_SUCCESS,
        response: { confirm: res.confirm, mobile: res.mobile },
      });
    }
  } catch (error) {
    // console.log(error)
    yield put({
      type: PHONE_ERROR,
      response: { message: "Something went wrong" },
    });
  }
}

function* watchLogin() {
  yield takeLatest(PHONE_SIGNIN, login);
}

export default watchLogin;
