//import liraries
import React, { Component, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import config from "../../config";
import PrimaryButton from "../../components/PrimaryButton";
import InputText from '../../components/InputText';
import { connect } from "react-redux";
import {
  verifyOTP,
  resendOTP,
} from "../../state-management/actions/otp.action";
import { AntDesign } from "@expo/vector-icons";

const CELL_COUNT = 6;
// create a component
const OtpVerify = (props) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertVisibleSuccess, setAlertVisibleSuccess] = useState(false);
  const [code,setCode] = useState("");

  useEffect(() => {
    if (props.error != "") {
      setAlertVisible(true);
    }
    if (props.success != "") {
      setAlertVisibleSuccess(true);
    }
  }, [props]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{ flexDirection: "row", alignItems: "center", padding: 20 }}
        >
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}
          >
            <AntDesign name="left" style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 30,
              fontFamily: "Roboto-Medium",
              color: config.primary_color,
            }}
          >
            OTP Verification
          </Text>
        </View>
        <Text style={styles.contentText}>
          Enter the OTP sent to your Phone
          {"\t"}
          <Text style={{ fontFamily: "Roboto-Bold" }}>{props.mobile}</Text>
        </Text>
        <View style={styles.inputContainer}>
          <InputText 
            keyboard={"numeric"}
            placeholder={"OTP"}
            value={code}
            onChangeText={(val)=>setCode(val)}
          />
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={styles.contentText}>
            Didn't receive the OTP?
            {"\t"}
          </Text>
          <TouchableOpacity
            onPress={() => {
              props.resendOTP(props.mobile);
            }}
          >
            <Text style={{ fontFamily: "Roboto-Bold", color: config.danger }}>
              RESEND OTP
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <View style={{ margin: 20, marginBottom: 40 }}>
        <PrimaryButton content={"Verify & Proceed"} clicked={()=>{
          props.verifyOTP({
            confirm: props.confirm,
            otp: code,
            page: props.page,
            signupData: props.signupData,
          });
        }} loading={props.isLoading} />
      </View>
    </SafeAreaView>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
  },
  backButton: {
    color: config.primary_color,
    width: 20,
    fontSize: 30,
    marginRight: 30,
    marginTop: 5,
    alignItems: "flex-start",
  },
  inputContainer: {
    paddingHorizontal: "8%",
  },
  borderStyleBase: {
    width: 30,
    height: 45,
  },

  borderStyleHighLighted: {
    borderColor: "red",
  },

  underlineStyleBase: {
    width: 33,
    height: 50,
    borderWidth: 0,
    borderBottomWidth: 1,
    fontSize: 25,
    color: "#545455",
  },

  underlineStyleHighLighted: {
    borderColor: config.primary_color,
    borderBottomWidth: 3,
  },
  otpColor: {
    color: "#545455",
  },
  contentText: {
    fontFamily: "Roboto-Medium",
    padding: "8%",
    color: config.dull_color,
  },

 
});

//make this component available to the app
export default connect((state) => ({ ...state.otp }), { verifyOTP, resendOTP })(
  OtpVerify
);
