//import liraries
import React, {useEffect} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  FlatList,
  ActivityIndicator
} from 'react-native';
import config from '../../config';
import AIcon from 'react-native-vector-icons/AntDesign';
import {changeMoneyPage,loadMyMoney} from '../../state-management/actions/mymoney.action';
import {connect} from 'react-redux';
import PieChartComponent from '../../components/PieChart';
import MyMoneyCard from '../../components/MyMoneyCard';
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';

const MyMoney = props => {
  const option= [
    {
      title: 'last 30 days',
    },
    {
      title: 'last week',
    },
    {
      title: 'last 24 hours'
    }
  ]

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'All', title: 'All' },
    { key: 'Received', title: 'Received' },
    { key: 'TobeReceived', title: 'To be Received' },
    { key: 'Paid', title: 'Paid' },
    { key: 'Settled', title: 'Settled' },
  ]);

  const FirstRoute = () => (
    <View style={styles.scene}>
            <FlatList
              data={props.requiredMilestonesData}
              renderItem={({item, index}) => {
                return <MyMoneyCard 
                clicked={()=>{props.navigation.navigate('TransactionDetails',{transactionData:{...item}})}}
                {...item}/>;
              }}
              keyExtractor={(item, index) => index.toString()}
            />
    </View>
  );
  
  const SecondRoute = () => (
    <View style={styles.scene}>
      <FlatList
              data={props.recievedList}
              renderItem={({item, index}) => {
                return <MyMoneyCard {...item}
                clicked={()=>{props.navigation.navigate('TransactionDetails',{transactionData:{...item}})}}
                />;
              }}
              keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
  const ThirdRoute = () => (
    <View style={styles.scene}>
      <FlatList
              data={props.toBeRecievedList}
              renderItem={({item, index}) => {
                return <MyMoneyCard 
                clicked={()=>{props.navigation.navigate('TransactionDetails',{transactionData:{...item}})}}
                {...item}/>;
              }}
              keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
  const FourthRoute = () => (
    <View style={styles.scene}>
      <FlatList
              data={props.paidList}
              renderItem={({item, index}) => {
                return <MyMoneyCard
                clicked={()=>{props.navigation.navigate('TransactionDetails',{transactionData:{...item}})}}
                {...item}
                />;
              }}
              keyExtractor={(item, index) => index.toString()}
            />
    </View>
  );
  const FifthRoute = () => (
    <View style={styles.scene}>
      <FlatList
              data={props.settledList}
              renderItem={({item, index}) => {
                return <MyMoneyCard 
                clicked={()=>{props.navigation.navigate('TransactionDetails',{transactionData:{...item}})}}
                {...item} />;
              }}
              keyExtractor={(item, index) => index.toString()}
            />
    </View>
  );
  const renderScene = SceneMap({
    All: FirstRoute,
    Received: SecondRoute,
    TobeReceived:ThirdRoute,
    Paid:FourthRoute,
    Settled:FifthRoute
  });
  const renderTabBar = props => (
    <TabBar
      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ color, margin: 8 ,fontFamily:'Roboto-Bold'}}>
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#FFF',
      shadowOffset: { height: 0, width: 0 }, 
      shadowColor: 'transparent',
      shadowOpacity: 0,
      elevation: 0  }}
      activeColor = {config.primary_color}
      inactiveColor = {'#9090A6'}
      scrollEnabled={true}
    />
  );

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
    props.loadMyMoney()
    })
    return unsubscribe;
  }, []);

  if (props.error) {
    alert(props.error);
  }
  
  const data_pie = [
    {
      key: 1,
      amount: 7000,
      svg: {fill: '#F50F9A'},
    },
    {
      key: 2,
      amount: 15000,
      svg: {fill: '#05DC29'},
    },
  ];
  const data_pie1 = [
    {
      key: 1,
      amount: 6000,
      svg: {fill: '#F55910'},
    },
    {
      key: 2,
      amount: 10000,
      svg: {fill: '#1297F1'},
    },
  ];
  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => props.navigation.goBack()}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          My Money
        </Text>
      </View>
    );
  };
  
  return props.isLoading?
  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
  <ActivityIndicator size="large" color={config.primary_color} />
</View>
  :(
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {renderHeader()}
        <View style={{marginHorizontal: '8%'}}>
          <View style={{flexDirection: 'row', marginVertical: '8%',justifyContent:'space-between'}}>
            <TouchableOpacity
              onPress={()=>{
                props.navigation.navigate('MyBank',{moneyToBeEarned:props.earnedChart[0].amount});
              }}
              style={{
                // backgroundColor: '#FFF',
                // elevation: 20,
                padding: 10,
                borderRadius: 100,
                borderColor:'#AAA',
                borderWidth:1
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Roboto-Regular',
                  color: config.primary_color,
                }}>
                My Bank A/c
              </Text>
            </TouchableOpacity>
            {/* <View style={{flex:0.3}}>
          <MyMoneyPicker data={option} onServiceSelected={serv => {}}/>
            </View> */}
          </View>

          
          
          <View>
          <PieChartComponent title={'Earnings'} data={props.earnedChart} text1={"To be \n Received"} text2={"Received"} />
          <View style={{height: 40}} />
          <PieChartComponent title={'Payments'} data={props.spentChart} text1={"Paid"} text2={"Settled"} />
          </View>

         

          <Text style={{fontSize:18,fontFamily:'Roboto-Medium',color:config.primary_color,marginTop:20}}>
            Transactions
          </Text>
        </View>

    <TabView
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      style={{
      shadowOffset: { height: 0, width: 0 }, 
      shadowColor: 'transparent',
      shadowOpacity: 0,
      elevation: 0 
      }}
    />

      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  backButton: {
    color: '#473BB3',
    fontSize: 22,
    paddingRight: 15,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  default: {
    textTransform: 'capitalize',
  },
  viewPager: {
    flex: 1,
  },
  scene: {
    flex: 1,
  },
});

//make this component available to the app
export default connect(
  state => ({paymentsTab: {...state.paymentstab}, ...state.myMoney}),
  {changeMoneyPage,loadMyMoney},
)(MyMoney);
