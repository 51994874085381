//import liraries
import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  Platform,
} from "react-native";
import config from "../../config";
import InputText from "../../components/InputText";
import PrimaryButton from "../../components/PrimaryButton";
import { connect } from "react-redux";
import { login } from "../../state-management/actions/login.action";
import { verifyOTPInit } from "../../state-management/actions/otp.action";
import { Formik } from "formik";
import * as yup from "yup";
import { AntDesign } from "@expo/vector-icons";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
// create a component
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      alertVisible: false,
      recaptcha: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      if (this.props.error != "") {
        alert(this.props.error);
        this.setState({ alertVisible: true });
      }
    }
    if (this.props.proceedWithOtp != prevProps.proceedWithOtp) {
      if (this.props.proceedWithOtp == true) {
        this.props.verifyOTPInit({
          confirm: this.props.confirm,
          mobile: this.props.mobile,
          page: "login",
        });
        this.props.navigation.navigate("OtpVerify");
      }
    }
  }

  componentDidMount() {
    if (Platform.OS != "web") {
      this.recaptchaVerifierRef
        .verify()
        .then((token) => {
          this.setState({ recaptcha: token });
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  handleMobileChange = (mobile) => {
    this.setState({ mobile });
  };

  handleSubmit = (values) => {
    if (values.mobile.trim() != "") {
      this.props.login(values.mobile, this.state.recaptcha);
    }
  };

  render() {
    var { mobile } = this.state;

    // if (this.props.route.params) {
    //   const { data } = this.props.route.params;
    //   if (data.hasOwnProperty("mobile")) {
    //     mobile = data.mobile;
    //   }
    // }

    var validationSchema;
    if (this.props.route.params) {
      const { data } = this.props.route.params;
      console.log(data);
      validationSchema = yup.object().shape({
        mobile: yup
          .string()
          .label("mobile")
          .required()
          .trim()
          .oneOf([...data.mobile]),
      });
    } else {
      validationSchema = yup.object().shape({
        mobile: yup.string().label("mobile").required(),
      });
    }

    return (
      <Formik
        initialValues={{
          mobile: mobile,
        }}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
            <ScrollView showsVerticalScrollIndicator={false}>
              {Platform.OS != "web" ? (
                <FirebaseRecaptchaVerifierModal
                  ref={(ref) => {
                    this.recaptchaVerifierRef = ref;
                  }}
                  firebaseConfig={config.firebase}
                  title={"Verify that you are not a robot"}
                  cancelLabel={"close"}
                  firebaseVersion={"7.9.0"}
                />
              ) : null}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => this.props.navigation.goBack()}
                >
                  <AntDesign name="left" style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: "Roboto-Medium",
                    color: config.primary_color,
                  }}
                >
                  Sign In
                </Text>
              </View>
              <View style={styles.inputContainer}>
                <InputText
                  name="mobile"
                  label="Mobile Number or Username"
                  placeholder="Enter your mobile number or username"
                  onChangeText={handleChange("mobile")}
                  onBlur={handleBlur("mobile")}
                  error={touched.mobile && errors.mobile}
                  value={values.mobile}
                  // disabled={
                  //   this.props.route.params?.data?.mobile ? true : false
                  // }
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.mobile && errors.mobile && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.mobile}
                    </Text>
                  )}
                  {touched.mobile && !errors.mobile && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#09C98F",
                      }}
                    >
                      Nice :)
                    </Text>
                  )}
                </View>
              </View>
            </ScrollView>
            <View style={{ margin: 20, marginBottom: 40 }}>
              <PrimaryButton
                content={"Get OTP"}
                loading={this.props.isLoading}
                clicked={handleSubmit}
              />
            </View>
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
  },
  backButton: {
    color: config.primary_color,
    width: 20,
    fontSize: 30,
    marginRight: 30,
    marginTop: 5,
    alignItems: "flex-start",
  },
  inputContainer: {
    padding: "8%",
  },
});

//make this component available to the app
export default connect((state) => ({ ...state.login }), {
  login,
  verifyOTPInit,
})(Login);
