//import liraries
import React, {useEffect, useState} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  Alert,
} from 'react-native'
import { Icon } from 'native-base'
import config from '../../config'
import PrimaryButton from '../../components/PrimaryButton'
import Picker from '../../components/Picker'
import {connect} from 'react-redux'
import {
  serviceSelected,
  productSelected,
  reset,
  loadObjects
} from '../../state-management/actions/category.action'
import AIcon from 'react-native-vector-icons/AntDesign';





// create a component
const Category = props => {
 const [alertVisible,setAlertVisible] = useState(false)
  useEffect(() => {
    props.reset()
    props.loadObjects()
    return () => {}
  }, [])

  function next () {
    if(props.isProductSelected || props.isServiceSelected)
      props.navigation.navigate('BuySellPage',{flow_code:props.route.params?.flow_code})
    else setAlertVisible(true)
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <StatusBar backgroundColor={config.primary_color} />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{flexDirection: 'row', padding: 20}}>
          <TouchableOpacity
            activeOpacity={0.6}
            style={{justifyContent: 'center'}}
            onPress={() => props.navigation.goBack()}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 26,
              fontFamily: 'OpenSans-Semibold',
              color: config.primary_color,
            }}>
            {props.route.params.title}
          </Text>
        </View>

        {/* ****************************** here is the block after header ************************** */}

        <View style={{flex: 1, marginHorizontal: '10%', marginTop: '5%'}}>
          <Text style={styles.txt1}>
          {props.route.params.description}
          </Text>

          {/* ************************  DropDown ******************************* */}

          <View style={{marginTop: 50}}>
            <Text style={styles.txt2}>Services</Text>
            <Picker
              name='Select Service'
              mode='0'
              data={props.services}
              onServiceSelected={serv => props.serviceSelected(serv)}
              imgName={require('../../assets/images/Group84.png')}
              deSelect={props.isProductSelected}
            />
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'OpenSans-Bold',
                fontSize: 20,
                marginVertical: '10%',
              }}>
              Or
            </Text>
            <Text style={styles.txt2}>Products</Text>
            <Picker
              name='Select Product'
              mode='1'
              data={props.products}
              onServiceSelected={prod => props.productSelected(prod)}
              imgName={require('../../assets/images/Group76.png')}
              deSelect={props.isServiceSelected}
            />
          </View>
          {/* ************************  DropDown END******************************* */}
          <View style={{marginVertical: 30}}>
            <PrimaryButton content='Proceed' clicked={() => next()} />
          </View>
        </View>
      </ScrollView>
      {/* <Overlay visible={alertVisible} onClose={()=>{setAlertVisible(false)}} animationType="zoomIn" 
                containerStyle={{backgroundColor: 'rgba(0,0,0,0.3)'}}
                childrenWrapperStyle={{backgroundColor: '#FFF',borderRadius:10}}
                animationDuration={500}>
                  {(hideModal, overlayState) => (
                <View style={{alignItems:'center',paddingVertical:10,lineHeight:21,paddingHorizontal:20,paddingBottom:0}}>
                  <Text style={{fontFamily:'Roboto-Regular',fontSize:16,color:config.primary_color,lineHeight:21}}>
                  Please select either a Service or a Product to continue.
                  </Text>
                  <View style={{paddingTop:20,alignSelf:'flex-end'}}>
                  <TouchableOpacity onPress={hideModal} style={{borderRadius:20,paddingVertical:5,margin:10,paddingHorizontal:35,backgroundColor:config.primary_color}}>
                    <Text style={{color:'#FFF',fontSize:15,fontFamily:'Roboto-Regular'}}>OK</Text>
                  </TouchableOpacity>
                  </View>
                </View>
                  )}
            </Overlay> */}
    </SafeAreaView>
  )
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 70,
    fontSize:30,
    marginRight: 30,
    marginTop:5,
    alignItems: 'flex-start',
  },
  txt1: {
    fontSize: 14,
    color: '#828282',
    fontFamily: 'OpenSans-Semibold',
  },
  txt2: {
    fontSize: 16,
    color: config.primary_color,
    fontFamily: 'Roboto-Medium',
    paddingLeft: 5,
    marginBottom: 10,
  },
})

//make this component available to the app
export default connect(state => ({...state.category}), {
  loadObjects,
  serviceSelected,
  productSelected,
  reset,
})(Category)
