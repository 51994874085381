
import React, {Component} from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { connect } from 'react-redux';
import Image1 from '../../assets/images/how_vouch_work.svg';
import PrimaryButton from '../../components/PrimaryButton';
import config from '../../config';

// create a component
class HowVouchWorks extends Component {
  render() {
    return (
      <View style={styles.container}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image1 
          // width={'85%'} height={'90%'}
           style={{alignSelf: 'center'}}/>
          {/* <Image source={require('../../assets/images/how_vouch_work1.png')} style={{width:'35%',height:'80%'}}/> */}
          {/* <PrimaryButton
              content={'Continue '}
              clicked={() => {
                this.props.navigation.navigate('Category');
              }}
            /> */}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent:'center',
            // marginRight: 40,
          }}>
          <TouchableOpacity
            onPress={() => {
              if(this.props.isLoginLater || !(this.props.recentContacts.total_payments_length)){
                this.props.navigation.navigate('BuySellPage',{flow_code:0});
              }else{
                this.props.navigation.navigate('Category',{title:'Go ahead, select a\nCategory',description:'You can protect payments for Goods and Services by paying through Vouch.',flow_code:0});
              }
            }}
            style={{backgroundColor: '#fff', borderRadius: 8}}>
            <Text
              style={{
                color: config.primary_color,
                fontFamily: 'OpenSans-Bold',
                fontSize: 12,
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}>
              Continue
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#481380',
    paddingVertical: '10%',
  },
  txt1: {
    fontFamily: 'OpenSans-Semibold',
    fontSize: 26,
    textAlign: 'right',
    color: '#FFF',
    marginBottom: 10,
    // marginRight:"5%"
    textAlign: 'center',
  },
  txt2: {
    fontSize: 20,
    color: '#FFF',
    fontFamily: 'Roboto-Medium',
    marginVertical: 20,
    textAlign: 'left',
  },
  txt3: {
    fontSize: 18,
    color: '#FFF',
    fontFamily: 'Roboto-Regular',
    marginBottom: 20,
    textAlign: 'left',
  },
  playStyles: {
    flex: 0.09,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: '10%',
  },
});

//make this component available to the app
export default connect(state => ({
  recentContacts: {...state.recentContacts},
  ...state.auth}), {
})(HowVouchWorks);
